import { environment } from './../../../environments/environment';
import { PartnerFactory } from './partner-factory';
import { ServiceResponse } from './../service-response';
import { throwError, Observable } from 'rxjs';
import { Partner } from './../../model/partner';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retry, catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {
  private endpointInfo = environment.host + environment.basePath + '/v1/partner';

  partnerList: Partner[];

  constructor(private http: HttpClient) { }

  private errorHandler(error: Error | any): Observable<any> {
    return throwError(() => error);
  }

  getAllInfo(uid): Observable<Partner> {
    return this.http
      .get<ServiceResponse>(`${this.endpointInfo}?uid=${uid}`)
      .pipe(
        retry(3),
        map(rsp => {
          if (rsp.payload === null) {
            return null;
          }
          return rsp.payload;
        } ),
        map(rawPartnerList => PartnerFactory.fromList(rawPartnerList as Partner[])),
        map(partnerList => (partnerList.length) > 0 ? partnerList[0] : null),
        catchError(this.errorHandler),
      );
  }
}
