<form class="ui large form" [formGroup]="userForm" (ngSubmit)="submitUsers()">
  <h2>Users</h2>
  <table cellspacing="0" formArrayName="users">
        <thead>
        <th>Username</th>
        <th>Password</th>
        <th><button class="ui small button"> <mat-icon matSuffix>save</mat-icon> </button></th>
        </thead>
        <tbody>
      <tr *ngFor="let user of users.controls; index as i; last as l" [formGroupName]="i">  
        <td><input type='text' formControlName="username"></td>
        <td><input type='password' formControlName="password"></td>
        <td><button *ngIf="l" mat-button (click)="addUser()" class="ui small button"> <mat-icon matSuffix>add</mat-icon> </button></td> 
      </tr>
      </tbody>
</table>
</form>