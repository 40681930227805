export class PartnerConsumer {
    constructor(
        public origin: string,
        public consumer: string,
        public partner_uid: string
    ) { }
}

export class User {
    constructor(
        public username: string,
        public password: string,
        public consumer: string
    ) { }

    public isValid(): boolean {
        return this.username.length>0 && this.password.length>=6 && this.consumer.length>0;
    }
}

export class Account {
    constructor(
        public consumer: string,
        public origin: string,
        public role: string,
        public test: boolean,
        public name: string,
        public apikey: string,
        public gateway_error: string,
        public user: User[]
    ) { }

    static create(): Account {
        return new Account(
            '', '', '', false, '', '', '', []
        )
    }
}

