import { throwError } from 'rxjs';
import { AuthData } from './auth-data';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';




import { catchError, retry } from 'rxjs/operators';
import { Router, NavigationExtras } from '@angular/router';
import * as decode from 'jwt-decode';
import {environment} from '../../environments/environment';

interface User {
  username: string;
  password: string;
}

const authServer = environment.host + '/auth';

@Injectable()
export class AuthService {

  constructor(private http: HttpClient, private authData: AuthData, private router: Router) { }

  private errorHandler(error: Error | any): Observable<never> {
    return throwError(() => error);
  }

  login(username: string, password: string) {
    const headers = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' })
        };

    return this.http.post(authServer, {username, password}, headers).pipe(
      retry(3),
      catchError(this.errorHandler),)
      .subscribe(res => {
        this.setSession(res);
        this.router.navigateByUrl('/search', /* Removed unsupported properties by Angular migration: relativeTo. */ {});
      });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
       `Backend returned code ${error.status}, ` +
       `body was: ${error.error}`);
    }
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }

  private setSession(authResult) {
    this.authData.setToken(authResult.token);
  }

  logout() {
    this.authData.removeToken();
    this.router.navigateByUrl('/');
  }

  public isLoggedIn() {
    return !this.authData.isEmpty() && !this.authData.isExpired();
  }

  isLoggedOut() {
    return this.authData.isEmpty();
  }

}
