import { AddressFactory } from './../address/address-factory';
import { Partner } from './../../model/partner';

export class PartnerFactory {
  static fromList(partnerList: Partner[]): Partner[] {
    const result = new Array<Partner>();
    if (partnerList === null) {
      return result;
    }
    for (const partner of partnerList) {
      result.push(PartnerFactory.fromObject(partner));
    }

    return result;
  }

  static fromObject(partner: Partner): Partner {
    if (partner === undefined || partner === null) {
      return null;
    }
    return new Partner(
          partner.uid,
          partner.reference_no,
          partner.partner_nr,
          partner.short_name,
          partner.long_name,
          AddressFactory.fromObject(partner.address)
        );
  }
}
