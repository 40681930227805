import { TimeTable } from './../model/timetable';
export class SearchResultField {
  constructor(
    public name: string,
    public value: string,
    public type?: string
  ) { }
}

export class OvCell {
  constructor(
    public v?: string
  ) { }
}

export class OvColumn {
  constructor(
    public id: number,
    public name: string,
    public field: string,
    public type: number,
  ) { }

  public getFieldName(): string {
    return this.field.replace('.', '_');
  }
}

export class OvRow {
  constructor(
    public id: string,
    public c: OvCell[],
    public link?: string
  ) { }
}

export class OvRowData {
  [key: string]: any;
}

export class Overview {
  constructor(
    public name: string,
    public size: number,
    public ttl: number,
    public subject: string,
    public searchString: string,
    public uniquekey: string,
    public header: OvColumn[],
    public row: OvRow[]
  ) { }
}
