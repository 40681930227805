<div class="ui right aligned grid" *ngIf="authService.isLoggedIn()">
  <div class="fourteen wide column"  style="padding:20px 0px 0px 0px;">
      <osca-dataspaces></osca-dataspaces>
  </div>
  <div class="column middle aligned" style="padding:20px 0px 0px 0px;width:32px">
    <div class="ui icon" *ngIf="authService.isLoggedIn()" (click)="authService.logout()"><i class="sign out circular link icon" alt="Logout"></i></div>
  </div>
</div>

<router-outlet></router-outlet>
