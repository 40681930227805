import { AuthService } from './auth/auth.service';
import { Component } from '@angular/core';

@Component({
  selector: 'osca-root',
  templateUrl: './app.component.html',
  styles: []
})
export class AppComponent {
  title = 'osca';
  authService: AuthService;
  constructor(private aService: AuthService) {
    this.authService = aService;
  }
}
