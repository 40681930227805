import { Delivery } from './../order/delivery';
import { EntityKey } from './../order/entitykey';
import { Remark } from './../remark/remark';

export class Container {
  constructor(
    public uid: string,
    public reference_no: string,
    public container_no: string,
    public container_type: string,
    public vendor_load: boolean,
    public provision_date: string,
    public yard_date: string,
    public handover_location: string,
    public volume: number,
    public gross_weight: number,
    public qty_ctn: number,
    public qty_pallets: number,
    public remark: Remark[],
    public booking: EntityKey[],
    public delivery: Delivery[]
  ) { }
}
