import { DateTimeFactory } from './../model/date-factory';
import { Router, ActivatedRoute } from '@angular/router';
import { MapPosition } from './../shared/map/map-position';
import { VesselRouteService } from './../shared/map/vessel-route.service';
import { Component, OnInit, Input } from '@angular/core';
import { icon, latLng, marker, polyline, tileLayer, MapOptions, TileLayer } from 'leaflet';
declare let L;

@Component({
  selector: 'osca-map-vessel',
  templateUrl: './map.component.html',
  styles: []
})

export class MapVesselComponent implements OnInit {
  markers: TileLayer[] = [];
  constructor( private vrs: VesselRouteService, private activatedRoute: ActivatedRoute ) { }

  ngOnInit() {

    document.getElementById('weathermap').innerHTML = '<div id="map" style="width: 100%; height: 100%;"></div>';

    const map = new L.map('map', {
        zoom: 5,
        center: [18.8, 112.75],
        zoomControl: true
    });

    tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      detectRetina: true,
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    const params = this.activatedRoute.queryParams.subscribe(p => {
       this.vrs.getRouteByVesselName(p['name'],
              DateTimeFactory.formatDateTime(p['from'], -3, 'DD.MM.YYYY'),
              DateTimeFactory.formatDateTime(p['to'], 3, 'DD.MM.YYYY'))
          .subscribe(posList => {
            if (posList === null || posList.length === 0) {
              this.getPositionByVesselName(map, p['name']);
              return;
            }
            this.create(map, posList);
          });
    });
  }

  getPositionByVesselName(map, name) {
    this.vrs.getPositionByVesselName(name).subscribe(posList => {
            this.create(map, posList);
          });
  }


  create(map: any, posList: MapPosition[]) {
    if (posList !== null && posList.length > 0) {
      const route = [];
      for (let i = 0; i < posList.length; i++) {
        route.push( latLng(posList[i].lat, posList[i].lon) );
        const m = marker(latLng(posList[i].lat, posList[i].lon), {
          icon: icon({
            iconSize: [ 16, 16 ],
            iconAnchor: [ 7, 7 ],
            iconUrl: 'assets/images/location.svg'
          })
        });
        m.bindTooltip(posList[i].timestamp);
        m.addTo(map);
      }

      if (posList.length > 1) {
        L.polyline(route).addTo(map);
      }

      const pos = posList[posList.length - 1];
      const lastMarker = this.createMarker(pos);
      lastMarker.bindTooltip(pos.title + '\n' + pos.timestamp);
      map.setView(lastMarker.getLatLng(), map.getZoom());
      lastMarker.addTo(map);
    }


  }

  createMarker(pos: MapPosition): any {
    return marker(latLng( pos.lat, pos.lon ), {
      icon: icon({
        iconSize: [ 12, 20 ],
        iconAnchor: [ 6, 20 ],
        iconUrl: 'leaflet/marker-icon.png'})
    });
  }

}
