<div *ngIf="dataSource.length>0" class="ui label">Content</div>
<table *ngIf="dataSource.length>0" class="ui celled very compact small table">
  <tbody>
    <tr>
      <td *ngFor="let column of dataSource[0]"><b> {{ column }} </b> </td>
    </tr>
    <tr *ngFor="let item of dataSource | slice:1">
      <td *ngFor="let cell of item"> {{ cell }}</td>
    </tr>
  </tbody>
<table>
