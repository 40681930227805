<div class="ui label" *ngIf="remarkList.length>1">Remarks ({{remarkList.length}})</div>
<div class="ui label" *ngIf="remarkList.length<=1">Remark</div>
<table class="ui single line stackable very compact small table">
  <tbody>
    <tr>
      <td><b>Reference No.</b></td>
      <td><b>Created at</b></td>
      <td><b>Created by</b></td>
      <td><b>Text</b></td>
    </tr>
    <tr *ngFor="let remark of remarkList">
      <td>{{ remark.reference_no }}</td>
      <td>{{ remark.created_at }}</td>
      <td>{{ remark.created_by}}</td>
      <td>{{ remark.content}}</td>
    </tr>
  </tbody>
</table>
