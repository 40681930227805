<div *ngIf="transactionList===undefined" style="width:100px"><mat-spinner></mat-spinner></div>
<div *ngIf="transactionList!==undefined && transactionList!=null" style="margin:10px">

<div class="ui label" *ngIf="transactionList.length>1">Transactions ({{transactionList.length}})</div>
<div class="ui label" *ngIf="transactionList.length<=1">Transaction</div>

<mat-tab-group>
  <mat-tab  *ngFor="let transaction of transactionList" label=" {{ transaction.timestamp }}">
    <ng-template matTabContent>
      <table class="ui single line stackable very compact small table">
      <tbody>
        <tr><td style="width:100px">Issuer</td><td>{{ transaction.identity.consumer }}</td></tr>
        <tr><td style="width:100px">Command</td><td>{{ transaction.command }}</td></tr>
        <tr><td style="width:100px">Reference No.</td><td>{{ transaction.referenceno }}</td></tr>
        <tr><td colspan="2"><button class="ui large button" (click)="onRetrigger(transaction.command,transaction.hash)">Retrigger</button></td></tr>
        <tr><td colspan="2" style="white-space:pre-wrap;word-wrap:break-word">
          <div *ngIf="transaction.format==='application/xml'" style="max-height:300px;overflow:auto">
            <pre lang="xml"><code>{{ transaction.payload }}</code></pre>
          </div>
          <div *ngIf="transaction.format!=='application/xml'" style="max-height:300px;overflow:auto">
            <code>{{ transaction.payload }}</code>
          </div>
        </td></tr>
      </tbody>
      </table>
    </ng-template>
  </mat-tab>
</mat-tab-group>
</div>