import { DateTimeFactory } from '../../model/date-factory';
import { Transaction } from './transaction';

export class TransactionFactory {
  static fromList(transactionList: Transaction[]): Transaction[] {
    if (transactionList === undefined) {
      return null;
    }
    for (let i=0; i<transactionList.length; i++) {
        transactionList[i].timestamp = DateTimeFactory.dateTimeValueParser(transactionList[i].timestamp)
    }
    return transactionList;
  }
}
