import { Component, OnInit, Input } from '@angular/core';
import { Partner } from '../../model/partner';
import { AccountService } from '../../shared/account/account.service';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { PartnerConsumer } from '../../shared/account/account';

@Component({
  selector: 'osca-partner-consumer',
  templateUrl: './partner-consumer.component.html',
  styles: []
})
export class PartnerConsumerComponent implements OnInit {
  @Input() origin: string;
  @Input() consumer: string;
  partnerForm: UntypedFormGroup;
  partnerList: UntypedFormArray;

  constructor(private as: AccountService,
    private fb: UntypedFormBuilder) { }

  ngOnInit() {
    this.as.getPartnerConsumers(this.origin).subscribe(partnerList => this.initPartnerList(partnerList));
    this.initPartnerList([]);
  }

  initPartnerList(pl: PartnerConsumer[]) {
    if (pl===undefined || pl==null) {
      pl = [];
    }

    pl.forEach(p => {
      const sa = p.partner_uid.split('-')
      if (sa.length>1) {
        p.partner_uid = sa[1];
      }
    });

    this.partnerList = this.fb.array(
      pl.map(
        p => this.fb.group({
          id: this.fb.control(p.partner_uid),
          consumer: this.fb.control(p.consumer),
          old_id: this.fb.control(p.partner_uid),
          old_consumer: this.fb.control(p.consumer)
        })
      )
    )

    if (pl.length==0) {
      this.addPartnerConsumer();
    }
    
    this.partnerForm = this.fb.group({
      partnerList: this.partnerList
    })
  }

  addPartnerConsumer() {
    this.partnerList.push(this.fb.group({id: '', consumer: '', old_id: '', old_consumer: ''}));
  }

  submitPartnerConsumer() {
    this.partnerForm.value.partnerList.forEach(element => {
      if (element.id === '' || element.consumer === '' || 
          (element.id == element.old_id && element.consumer == element.old_consumer)
        ) {
        return;
      }

      const partnerConsumer = {
        origin: this.origin,
        partner_uid: this.origin + '-' + element.id,
        consumer: element.consumer}
      
      if (element.old_id==='') {
        this.as.createPartnerConsumer(partnerConsumer);
      } else {
        this.as.updatePartnerConsumer(element.old_id, element.old_consumer, partnerConsumer);
      }
    });
  }
}
