import { AppRoutingModule } from './app-routing.module';
import { RouterModule} from '@angular/router';
import { QuotationServiceComponent } from './quotation/quotation-service.component';
import { QuotationPositionComponent } from './quotation/quotation-position.component';
import { MapOrderComponent } from './map/map-order.component';
import { ShipmentService } from './shared/shipment/shipment.service';
import { QuotationService } from './shared/quotation/quotation.service';
import { AuthData } from './auth/auth-data';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthService } from './auth/auth.service';
import { BookingService } from './shared/booking/booking.service';
import { DataStoreService } from './shared/data-store.service';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { AppComponent } from './app.component';
import { SearchFieldComponent } from './overview/search-field/search-field.component';
import { BookingComponent } from './booking/booking.component';
import { AddressComponent } from './address/address.component';
import { TimetableComponent } from './timetable/timetable.component';
import { PartnerComponent } from './partner/partner.component';
import { ContainerComponent } from './container/container.component';
import { DeliveryComponent } from './delivery/delivery.component';
import { AuthFormComponent } from './auth/auth-form.component';
import { AuthInterceptor } from './auth/auth-interceptor';
import { SearchResultComponent } from './overview/search-result/search-result.component';
import { DataspacesComponent } from './dataspaces/dataspaces.component';
import { SearchFrameComponent } from './overview/search-frame/search-frame.component';
import { ShipmentComponent } from './shipment/shipment.component';
import { QuotationComponent } from './quotation/quotation.component';
import { TransactionComponent } from './transaction/transaction.component';
import { AgGridModule } from 'ag-grid-angular';
import { MatTabsModule } from '@angular/material/tabs';
import { RemarkComponent } from './remark/remark.component';
import { PartnerTabsComponent } from './partner-tabs/partner-tabs.component';
import { EventListComponent } from './event-list/event-list.component';
import { OrderComponent } from './order/order.component';
import { PriceComponent } from './order/price/price.component';
import { CustomsInfoComponent } from './order/customs-info/customs-info.component';
import { ContentComponent } from './order/content/content.component';
import { MapVesselComponent } from './map/map-vessel.component';
import { MapAddressComponent } from './map/map-address.component';
import { ItemComponent } from './item/item.component';
import { AccountComponent } from './account/account.component';
import { AccountService } from './shared/account/account.service';
import { UserComponent } from './account/user/user.component';
import { PartnerConsumerComponent } from './account/partner-consumer/partner-consumer.component';
import { MapTruckComponent } from './map/map-truck.component';
import { DocumentComponent } from './document/document.component';
import { DownloadService } from './download/download.service';
import { DownloadComponent } from './download/download.component';
import { MessageComponent } from './message/message.component';
import { MessageService } from './shared/message/message.service';
import { PrintPDFService } from './print-pdf/print-pdf.service';
import { PrintPDFComponent } from './print-pdf/print-pdf.component';
import { TrackingComponent } from './tracking/tracking.component';
import { TrackingService } from './shared/tracking/tracking.service';
import { TransactionService } from './shared/transaction/transaction.service';

@NgModule({
  declarations: [
    AppComponent,
    SearchFieldComponent,
    BookingComponent,
    AccountComponent,
    AddressComponent,
    TimetableComponent,
    PartnerComponent,
    ContainerComponent,
    DocumentComponent,
    DownloadComponent,
    PrintPDFComponent,
    DeliveryComponent,
    AuthFormComponent,
    SearchResultComponent,
    DataspacesComponent,
    SearchFrameComponent,
    ShipmentComponent,
    QuotationComponent,
    QuotationPositionComponent,
    QuotationServiceComponent,
    TransactionComponent,
    RemarkComponent,
    PartnerTabsComponent,
    EventListComponent,
    OrderComponent,
    PriceComponent,
    CustomsInfoComponent,
    ContentComponent,
    MapVesselComponent,
    MapTruckComponent,
    MapAddressComponent,
    MapOrderComponent,
    MessageComponent,
    ItemComponent,
    AccountComponent,
    UserComponent,
    PartnerConsumerComponent,
    TrackingComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatInputModule,
    MatSelectModule,
    MatTabsModule,
    MatIconModule,
    BrowserAnimationsModule,
    RouterModule,
    AgGridModule,
    LeafletModule
  ],
  providers: [
    AccountService,
    DataStoreService,
    BookingService,
    ShipmentService,
    QuotationService,
    TransactionService,
    DownloadService,
    PrintPDFService,
    MessageService,
    TrackingService,
    AuthData,
    AuthService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
