import * as moment from 'moment';

export class DateTimeFactory {
  static dateValueParser(value) {
    if (value === undefined || value === '') {
      return '';
    }
    const r = moment(value).locale('de').format('L');
    return r;
  }

  static dateTimeCellParser(cell) {
    if (cell===undefined || cell===null) {
      return '';
    }
    return DateTimeFactory.dateTimeValueParser(cell.value)
  }

  static dateTimeValueParser(value) {
    if (value === undefined || value === null || value === '') {
      return '';
    }

    if (value.endsWith('T00:00:00Z')) {
      value = value.replace('T00:00:00Z', '');
    }

    let r = moment(value).locale('de').format('L LT');
    if (r.endsWith(' 00:00')) {
      r = r.replace(' 00:00', '');
    }
    return r;
  }

  static now() {
    return moment().locale('de').format('L LT');
  }

  static formatNow(format: string) {
    if (format === '') {
      return moment().locale('de').format();
    }
    return moment().locale('de').format(format);
  }

  static formatDateTime(value, days2add, format) {
    if (value === undefined || value === null || value === '') {
      return '';
    }

    return moment(value, format).add(days2add, 'days').format('YYYY-MM-DD');
  }
}
