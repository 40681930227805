
import {catchError} from 'rxjs/operators';
import { AuthData } from './auth-data';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHandler } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { HttpEvent } from '@angular/common/http';
import {
  HttpResponse,
  HttpErrorResponse,
  HttpInterceptor
} from '@angular/common/http';




@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor( private router: Router, private authData: AuthData ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const token = this.authData.getToken();
      let request: HttpRequest<any>;
      if (token) {
        if (!req.headers.has('Accept'))
          req.headers.set('Accept', 'application/json')
        request = req.clone({
          headers: req.headers.set('Authorization', 'Bearer ' + token)
        });
      } else {
        request = req;
      }

      return next
        .handle(request).pipe(
        catchError(response => {
          if (response instanceof HttpErrorResponse) {
            if (response.status === 401) {
              this.router.navigateByUrl('/login');
            }
            if (!response.ok) {
             // window.alert('URL: ' + response.url + '\nStatus: ' + response.status + '\nMessage: ' + response.message);
              this.showError(response.error, response.url);
              return  new Observable<HttpEvent<any>>();
            }
          }
          this.showError(response.error, response.url);
          return new Observable<HttpEvent<any>>();
        }));
    }

    showError(error, url) {
      if (error === undefined || error === null) {
        return;
      }
      if (error.error === undefined || error.error === null) {
        return;
      }
      if (error.error.length === 0) {
        return;
      }
      const errMsg = error.error[0];
      if (errMsg!==undefined && errMsg.description !== undefined ) {
        window.alert('Error: ' + errMsg.description);
      } else {
        console.log(error.error)
      }
    }
}
