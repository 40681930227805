import { Component, OnInit, Input } from '@angular/core';
import { Item } from './../shared/order/item';

@Component({
  selector: 'osca-item',
  templateUrl: './item.component.html',
  styles: []
})

export class ItemComponent implements OnInit {
  @Input() itemList: Item[];

  constructor() { }

  ngOnInit() {
  }

}
