import { DeliveryFactory } from './../order/delivery-factory';
import { DateTimeFactory } from './../../model/date-factory';
import { Container } from './container';

export class ContainerFactory {
  static fromList(containerList: Container[]): Container[] {
    const result = new Array<Container>();
    if (containerList === undefined) {
      return result;
    }
    for (const rawContainer of containerList) {
      result.push(new Container(
        rawContainer.uid,
        rawContainer.reference_no,
        rawContainer.container_no,
        rawContainer.container_type,
        rawContainer.vendor_load,
        DateTimeFactory.dateTimeValueParser(rawContainer.provision_date),
        DateTimeFactory.dateTimeValueParser(rawContainer.yard_date),
        rawContainer.handover_location,
        rawContainer.volume,
        rawContainer.gross_weight,
        rawContainer.qty_ctn,
        rawContainer.qty_pallets,
        rawContainer.remark,
        rawContainer.booking,
        DeliveryFactory.fromList(rawContainer.delivery)
      ));
    }
    return result;
  }
}
