
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {retry, map, catchError} from 'rxjs/operators';
import { OrderFactory } from './order-factory';
import { ServiceResponse } from './../service-response';
import { HttpClient } from '@angular/common/http';
import { Order } from './order';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  private endpoint = environment.host + environment.basePath + '/v1/order';

  orderList: Order[];

  constructor(private http: HttpClient) { }

  private errorHandler(error: Error | any): Observable<any> {
    return observableThrowError(error);
  }

  getSingle(uid): Observable<Order> {
    return this.http
      .get<ServiceResponse>(`${this.endpoint}?uid=${uid}`).pipe(
      retry(3),
      map(rsp => rsp.payload[0]),
      map(rawOrder => OrderFactory.fromObject(rawOrder as Order)),
      catchError(this.errorHandler),);
  }

  getAll(uid): Observable<Array<Order>> {
    return this.http
      .get<ServiceResponse>(`${this.endpoint}?uid=${uid}`).pipe(
      retry(3),
      map(response => response.payload),
      catchError(this.errorHandler),);
  }
}
