<div class="ui label" *ngIf="deliveryList.length>1">Purchase Orders ({{deliveryList.length}})</div>
<div class="ui label" *ngIf="deliveryList.length<=1">Purchase Order</div>
<mat-tab-group>
  <mat-tab  *ngFor="let delivery of deliveryList" label="{{ delivery.customer_refno}}">
    <ng-template matTabContent>
     <table class="ui single line stackable collapsing very compact small table">
      <tbody>
        <tr>
          <td><b>Reference No.</b></td>
          <td>{{ delivery.reference_no }}</td>
        </tr>
        <tr *ngIf="delivery.order_no">
          <td><b>Order No.</b></td>
          <td>
            <a (click)="router.navigateByUrl('/order/'+delivery.purchase_order.uid)">{{ delivery.order_no }}</a>
          </td>
        </tr>
        <tr *ngIf="delivery.order_date">
          <td><b>Order Date</b></td>
          <td>{{ delivery.order_date }}</td>
        </tr>
        <tr *ngIf="delivery.order_type">
          <td><b>Order Type</b></td>
          <td>{{ delivery.order_type }}</td>
        </tr>
        <tr *ngIf="delivery.department">
          <td><b>Department</b></td>
          <td>{{ delivery.department }}</td>
        </tr>
        <tr *ngIf="delivery.origin_country">
          <td><b>Country of Origin</b></td>
          <td>{{ delivery.origin_country }}</td>
        </tr>
        <tr *ngIf="delivery.item_number">
          <td><b>Article No.</b></td>
          <td>{{ delivery.item_number }}</td>
        </tr>
        <tr *ngIf="delivery.item_description">
          <td><b>Article Description</b></td>
          <td>{{ delivery.item_description }}</td>
        </tr>
        <tr *ngIf="delivery.item_position">
          <td><b>Order Position</b></td>
          <td>{{ delivery.item_position }}</td>
        </tr>
        <tr *ngIf="delivery.additional_info">
          <td><b>Additional Info</b></td>
          <td>{{ delivery.additional_info }}</td>
        </tr>
        <tr *ngIf="delivery.qty_pcs">
          <td><b>Qty pcs.</b></td>
          <td>{{ delivery.qty_pcs }} {{ delivery.unit }}</td>
        </tr>
        <tr *ngIf="delivery.qty_ctn>0">
          <td><b>Qty ctn.</b></td>
          <td>{{ delivery.qty_ctn }}</td>
        </tr>
        <tr *ngIf="delivery.volume>0">
          <td><b>Volume</b></td>
          <td>{{ delivery.volume }} m³</td>
        </tr>
        <tr *ngIf="delivery.weight>0">
          <td><b>Weight</b></td>
          <td>{{ delivery.weight }} kg</td>
        </tr>
        <tr *ngIf="delivery.dispatch_type">
          <td><b>Dispatch Type</b></td>
          <td>{{ delivery.dispatch_type }}</td>
        </tr>
        <tr *ngIf="delivery.customs_info?.category">
          <td><b>Customs Category</b></td>
          <td>{{ delivery.customs_info?.category }}</td>
        </tr>
        <tr *ngIf="delivery.customs_info?.customs_tariff_no">
          <td><b>Customs Tariff No.</b></td>
          <td>{{ delivery.customs_info?.customs_tariff_no }}</td>
        </tr>
        <tr *ngIf="delivery.customs_info?.country">
          <td><b>Customs Country</b></td>
          <td>{{ delivery.customs_info?.country }}</td>
        </tr>
        <tr *ngIf="delivery.customs_info?.description">
          <td><b>Customs Description</b></td>
          <td>{{ delivery.customs_info?.description }}</td>
        </tr>
      </tbody>
     </table>
    </ng-template>
  </mat-tab>
</mat-tab-group>
