export class Address {
  public fullAddress: string;

  constructor(
    public reference_no: string,
    public street: string,
    public zip: string,
    public city: string,
    public country: string,
    public contact_name?: string,
    public email?: string,
    public phone?: string,
    public fax?: string,
    public lon?: number,
    public lat?: number,
    public name?: string,
    public type?: string,
  ) {
    this.fullAddress = '';
    this.fullAddress = this.add(this.fullAddress, street);
    this.fullAddress = this.add(this.fullAddress, zip);
    this.fullAddress = this.add(this.fullAddress, city);
    this.fullAddress = this.add(this.fullAddress, country);
  }

  private add(target: string, addr: string): string {
    if (addr !== undefined && addr !== null) {
      if (target.length > 0) {
        return target + '+' + addr.replace(' ', '+');
      }
      return addr.replace(' ', '+');
    }
    return target;
  }

}
