<div *ngIf="threadList!==undefined && threadList!=null && threadList.length>0">

<div class="ui label">Messages</div>
  <div *ngFor="let thread of threadList">
  <table class="ui single line stackable very compact small table">
    <tbody>
      <tr>
        <td><b>Sender</b></td>
        <td><b>Created at</b></td>
        <td><b>Text</b></td>
      </tr>
      <tr *ngFor="let email of thread.email">
        <td>{{ email.sender.name }} &lt;{{ email.sender.addr }}&gt;</td>
        <td>{{ email.created_at }}</td>
        <td><p [innerHTML]="email.text"></p></td>
      </tr>
    </tbody>
  </table>
  </div>

</div>
