import { PartnerFactory } from './../partner/partner-factory';
import { TimeTableFactory } from './../../timetable/timetable-factory';
import { DateTimeFactory } from './../../model/date-factory';
import { Delivery } from './delivery';

export class DeliveryFactory {
  static fromList(deliveryList: Delivery[]): Delivery[] {
    if (deliveryList === undefined) {
      return null;
    }
    const result = new Array<Delivery>();
    for (const rawDelivery of deliveryList) {
      const delivery = rawDelivery;
      delivery.created_at = DateTimeFactory.dateTimeValueParser(rawDelivery.created_at);
      delivery.updated_at = DateTimeFactory.dateTimeValueParser(rawDelivery.updated_at);
      delivery.order_date = DateTimeFactory.dateTimeValueParser(rawDelivery.order_date);
      delivery.timetable = TimeTableFactory.fromObject(rawDelivery.timetable);
      delivery.customer = PartnerFactory.fromObject(rawDelivery.customer);
      delivery.forwarder = PartnerFactory.fromObject(rawDelivery.forwarder);
      delivery.supplier = PartnerFactory.fromObject(rawDelivery.supplier);
      delivery.shipper = PartnerFactory.fromObject(rawDelivery.shipper);
      delivery.factory = PartnerFactory.fromObject(rawDelivery.factory);
      result.push(delivery);
    }
    return result;
  }
}
