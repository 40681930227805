<div class="ui label" *ngIf="containerList.length>1">Containers ({{containerList.length}})</div>
<div class="ui label" *ngIf="containerList.length<=1">Container</div>

<mat-tab-group>
  <mat-tab  *ngFor="let container of containerList" label="Container {{ container.reference_no }}">
    <ng-template matTabContent>
      <table class="ui single line stackable very compact small table">
            <tbody>
              <tr>
                <td><b>UID</b></td>
                <td><b>Container No.</b></td>
                <td *ngIf="container.booking!==null && container.booking!==undefined && container.booking.length>0"><b>Booking</b></td>
                <td><b>Container Type</b></td>
                <td><b>Vendor Load</b></td>
                <td><b>Provision Date</b></td>
                <td><b>Yard Date</b></td>
                <td><b>Handover Location</b></td>
                <td *ngIf="container.volume"><b>Volume</b></td>
                <td *ngIf="container.gross_weight"><b>Gross Weight</b></td>
                <td *ngIf="container.qty_ctn"><b>Qty Ctn.</b></td>
                <td *ngIf="container.qty_pallets"><b>Qty Pallets</b></td>
              <tr>
                <td>{{ container.uid }}</td>
                <td>{{ container.container_no }}</td>
                <td *ngIf="container.booking">
                  <a *ngFor="let booking of container.booking" (click)="router.navigateByUrl('/booking/'+booking.uid)">{{ booking.uid }}</a>
                </td>
                <td>{{ container.container_type }}</td>
                <td>{{ container.vendor_load }}</td>
                <td>{{ container.provision_date }}</td>
                <td>{{ container.yard_date }}</td>
                <td>
                  <a *ngIf="container.handover_location"
                    target="_blank" [routerLink]="['/map_address']"
                    [queryParams]="{ address: container.handover_location }">
                    {{ container.handover_location }}
                  </a>
                </td>
                <td *ngIf="container.volume">{{ container.volume }} m³</td>
                <td *ngIf="container.gross_weight">{{ container.gross_weight }} kg</td>
                <td *ngIf="container.qty_ctn">{{ container.qty_ctn }}</td>
                <td *ngIf="container.qty_pallets">{{ container.qty_pallets }}</td>
          <!--   <td>Remarks</td><td>{{ container.remark: Remark[] }}</td>-->
              </tr>
            </tbody>
          </table>

          <osca-remark *ngIf="container.remark && container.remark>0" [remarkList]="container.remark"></osca-remark>
          <osca-tracking
              *ngIf="shipmentUID!==undefined && shipmentUID.length>0 && container.container_no!==undefined && container.container_no.length==11" 
              [shipmentUID]="shipmentUID" [containerNo]="container.container_no"
              ></osca-tracking>

          <div class="ui row" *ngIf="container.delivery">
            <osca-delivery [deliveryList]="container.delivery" style="overflow-x: scroll"></osca-delivery>
          </div>


    </ng-template>
  </mat-tab>
</mat-tab-group>
