import { DateTimeFactory } from './../model/date-factory';
import { TimeTable } from './../model/timetable';
import * as moment from 'moment';

export class TimeTableFactory {
  static fromObject(rawTimeTable: TimeTable): TimeTable {
    if (rawTimeTable === undefined) {
      return undefined;
    }
    const result = rawTimeTable;
    result.handover = DateTimeFactory.dateTimeValueParser(rawTimeTable.handover);
    result.etd = DateTimeFactory.dateTimeValueParser(rawTimeTable.etd);
    result.eta = DateTimeFactory.dateTimeValueParser(rawTimeTable.eta);
    result.delivery_date = DateTimeFactory.dateTimeValueParser(rawTimeTable.delivery_date);
    return result;
  }
}
