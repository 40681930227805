
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {retry, catchError} from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Injectable, Input } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  @Input() name: string;

  private endpoint = environment.host + environment.basePath + '/v1/file';

  constructor(private http: HttpClient) { }

  private errorHandler(error: Error | any): Observable<any> {
    return observableThrowError(error);
  }

  getSingle(uid, docno): Observable<Blob> {
    let requestOptions: Object = {
      headers: new HttpHeaders({
        'Accept':  'application/octet-stream',
      }), 
      responseType: 'blob',
    };
    return this.http.get<Observable<Blob>>(`${this.endpoint}/shipment/${uid}/doc/${docno}`, requestOptions)
      .pipe(
      retry(3),
      catchError(this.errorHandler));
  }

}
