import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { User } from '../../shared/account/account';
import { Observable, BehaviorSubject } from 'rxjs';
import { UserFactory } from '../../shared/account/account-factory';
import { AccountService } from '../../shared/account/account.service';

@Component({
  selector: 'osca-user',
  templateUrl: './user.component.html',
  styles: []
})
export class UserComponent implements OnInit, OnChanges {
  userForm: UntypedFormGroup;
  users: UntypedFormArray;

  @Input() userList: User[];
  @Input() consumer: string;

  
  
  constructor(
    private fb: UntypedFormBuilder,
    private as: AccountService
  ) { }

  ngOnInit() {
    this.createUserList(this.userList);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.createUserList(this.userList);
  }

  createUserList(ul: User[]) {
    if (ul==null) {
      return
    }
    this.users = this.fb.array(
      ul.map(
        u => this.fb.group({
          username: this.fb.control(u.username),
          password: this.fb.control(u.password),
          id: u.username
        })
      )
    )
  
    if (ul.length==0) {
      this.addUser();
    }
    
    this.userForm = this.fb.group({
      users: this.users
    })
  }

  addUser() {
    this.users.push(this.fb.group({username: '', password: '', id: ''}))
  }

  submitUsers() {
    
    this.userForm.value.users.forEach(element => {
      const user = UserFactory.fromObject(this.consumer, element);
      if (user==null || !user.isValid()) {
        console.error('user in not valid')
        return
      }

      if (element.id==='') {
        this.as.createUser(user);
      } else if (UserFactory.isChanged(element)) {
        this.as.updateUser(element.id, user);
      }
    });
  }

}
