import { TimeTable } from './../model/timetable';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'osca-timetable',
  templateUrl: './timetable.component.html',
  styles: []
})
export class TimetableComponent implements OnInit {
  @Input() timetable: TimeTable;

  constructor() { }

  ngOnInit() {
  }

}
