import { User } from "./account";

export class UserFactory {
    static fromObject(consumer: string, o: any): User {
        if (o===undefined || o==null) {
            return null;
        }
        return new User(o.username, o.password, consumer)
    }

    static isChanged(o: any): boolean {
        if (o===undefined || o==null) {
            return false;
        }
        return o.username!=o.id || o.password.length<16;
    }
}
