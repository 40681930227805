import { Container } from './../container/container';
import { Document } from './../document/document';
import { Remark } from './../remark/remark';
import { Partner } from './../../model/partner';
import { TimeTable } from './../../model/timetable';
export class Shipment {
  constructor(
    public uid: string,
    public reference_no: string,
    public version: number,
    public created_at: string,
    public updated_at: string,
    public status: string,
    public status_forwarder: string,
    public timetable: TimeTable,
    public carrier: string,
    public vessel_airline: string,
    public remark: Remark[],
    public document: Document[],
    public container: Container[],
    public customer?: Partner,
    public forwarder?: Partner,
  ) { }
}
