import { ActivatedRoute, RouterModule, Router } from '@angular/router';
import { AuthService } from './auth.service';
import {Component, OnInit, Input} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import {environment} from '../../environments/environment';

@Component({
  selector: 'osca-auth',
  templateUrl: './auth-form.component.html',
  styles: [
    '.body { background-color: #DADADA; }',
    '.image { margin-top: -100px;}',
    '.column { max-width: 450px;}'
  ]
})

export class AuthFormComponent implements OnInit{
  @Input() version: string;

  authForm = new UntypedFormGroup({
    username: new UntypedFormControl(),
    password: new UntypedFormControl()
  });
  wrongPasswordIsEntered = false;
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    if (this.authService.isLoggedIn()) {
      this.router.navigateByUrl('/search');
    }

    if (this.router.url === '/login') {
      this.router.navigateByUrl('/login');
    }

    this.version = environment.version;
  }

  login() {
    const val = this.authForm.value;
    if (val.username && val.password) {
      this.authService.login(val.username, val.password);
    }
    if (!this.authService.isLoggedIn()) {
      this.wrongPasswordIsEntered = true;

    }
  }

  closeErrorMessage(): void {
    this.wrongPasswordIsEntered = false;
  }



}
