export class EventConsumer {
  constructor(
    public consumer: string,
    public role: string,
    public acknowledgement: string
  ) { }
}

export class Acknowledgement {
  constructor(
    public uid: string,
    public consumers: EventConsumer[],
    public action: number,
    public timestamp: string
  ) { }
}
