import { QuotationComponent } from './quotation/quotation.component';
import { MapOrderComponent } from './map/map-order.component';
import { MapAddressComponent } from './map/map-address.component';
import { OrderComponent } from './order/order.component';
import { ShipmentComponent } from './shipment/shipment.component';
import { SearchFrameComponent } from './overview/search-frame/search-frame.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule} from '@angular/router';

import { BookingComponent } from './booking/booking.component';
import { MapVesselComponent } from './map/map-vessel.component';
import { MapTruckComponent } from './map/map-truck.component';
import { AuthFormComponent } from './auth/auth-form.component';
import { AccountComponent } from './account/account.component';
import { DownloadComponent } from './download/download.component';
import { PrintPDFComponent } from './print-pdf/print-pdf.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: AuthFormComponent,
    children: [
      { path: '**', redirectTo: '/login', pathMatch: 'full' },
    ]
  },
  {
    path: 'search',
    component: SearchFrameComponent
  },
  {
    path: 'search/:subject',
    component: SearchFrameComponent
  },
  {
    path: 'account/:consumer',
    component: AccountComponent
  },
  {
    path: 'account/?new',
    component: AccountComponent
  },
  {
    path: 'search/:subject/:text',
    component: SearchFrameComponent
  },
  {
    path: 'logout',
    component: AuthFormComponent
  },
  {
    path: 'map_vessel',
    component: MapVesselComponent
  },
  {
    path: 'map_truck',
    component: MapTruckComponent
  },
  {
    path: 'map_address',
    component: MapAddressComponent
  },
  {
    path: 'map_order',
    component: MapOrderComponent
  },
  {
    path: 'booking/:uid',
    component: BookingComponent
  },
  {
    path: 'order/:uid',
    component: OrderComponent
  },
  {
    path: 'shipment/:uid',
    component: ShipmentComponent
  },
  {
    path: 'shipment/:uid/doc/:docno',
    component: DownloadComponent
  },
  {
    path: ':subject/:uid',
    component: PrintPDFComponent
  },
  {
    path: 'quotation/:uid',
    component: QuotationComponent
  },
  {
    path: '**',
    component: AuthFormComponent,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})

export class AppRoutingModule { }
