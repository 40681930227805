
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {map, retry, catchError} from 'rxjs/operators';
import { MapPositionFactory } from './map-position-factory';
import { ServiceResponse } from '../service-response';
import { HttpClient } from '@angular/common/http';
import { MapPosition } from './map-position';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { GoogleRoute } from './google-api';

@Injectable({
  providedIn: 'root'
})
export class TruckRouteService {
  private endpointRoute = environment.host + environment.basePath + '/v1/routing/route';

  posList: MapPosition[];

  constructor(private http: HttpClient) { }

  private errorHandler(error: Error | any): Observable<any> {
    return observableThrowError(error);
  }

  getRouteByTruck(departure, arrival, etd): Observable<MapPosition[]> {
    return this.http
      .get<ServiceResponse>(`${this.endpointRoute}?departure=${departure}&arrival=${arrival}&start_time=${etd}`).pipe(
      retry(3),
      map(rsp => rsp.payload === undefined ? null : rsp.payload ),
      map(rawPos => {
        this.posList = MapPositionFactory.fromGoogleRoute(etd, rawPos as GoogleRoute[]);
        return this.posList;
       } ),
      catchError(this.errorHandler),);
  }

}
