
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {retry, catchError} from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrintPDFService {

  private endpoint = environment.host + environment.basePath + '/v1/pdf';

  constructor(private http: HttpClient) { }

  private errorHandler(error: Error | any): Observable<any> {
    return observableThrowError(() => error);
  }

  getSingle(subject, uid): Observable<Blob> {
    let requestOptions: Object = {
      headers: new HttpHeaders({
        'Accept':  'application/octet-stream',
      }), 
      responseType: 'blob',
    };
    return this.http.post<Observable<Blob>>(`${this.endpoint}/${subject}?uid=${uid}`, null, requestOptions)
      .pipe(
      retry(3),
      catchError(this.errorHandler));
  }

}
