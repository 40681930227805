export class WebhookInfo {
  constructor(
    public service: string,
    public uid: string,
    public info: string,
    public content: string,
    public timestamp: string,
    public ok: boolean
  ) { }
}
