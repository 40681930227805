import { Partner } from './../model/partner';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'osca-partner-tabs',
  templateUrl: './partner-tabs.component.html',
  styles: []
})
export class PartnerTabsComponent implements OnInit {

  @Input() customer: Partner;
  @Input() supplier: Partner;
  @Input() shipper: Partner;
  @Input() factory: Partner;
  @Input() forwarder: Partner;
  @Input() warehouse: Partner;
  @Input() final_customer: Partner;

  constructor() { }

  ngOnInit() {
  }

}
