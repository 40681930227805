import { Overview, OvRowData, OvColumn } from '../../shared/search-result';
import { DataStoreService } from '../../shared/data-store.service';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DateTimeFactory } from '../../model/date-factory';
import { IGetRowsParams } from 'ag-grid-community';
import { FilterFactory } from '../filter/filter-factory';

@Component({
  selector: 'osca-search-result',
  templateUrl: './search-result.component.html',
  styles: [
    '.container { display: flex; flex-direction: column; height: 100%; margin: 20px; }',
    'ag-grid-angular{ height: 520px; }',
    '.container .ag-grid-angular { flex-basis: 100%; display: flex; flex-grow: 1; overflow-x: auto; }'
  ]
})
export class SearchResultComponent implements OnInit {
  subject: string;
  searchString: string;
  columnDefs = [];
  defaultColDef = {};
  totalRows: Number;

  private gridApi;
  private gridColumnApi;

  components;
  rowBuffer;
  rowSelection;
  rowModelType;
  paginationPageSize;
  cacheOverflowSize;
  maxConcurrentDatasourceRequests;
  infiniteInitialRowCount;
  cacheBlockSize;

  constructor(private dataStore: DataStoreService,
              private router: Router,
              private route: ActivatedRoute) {

    this.components = {
      loadingRenderer: function(params) {
        if (params.value !== undefined) {
          return params.value;
        } else {
          return '<img src="src/assets/images/logo.gif">';
        }
      }
    };
    this.rowBuffer = 0;
    this.rowSelection = "multiple";
    this.rowModelType = "infinite";
    this.paginationPageSize = 20;
    this.cacheOverflowSize = 2;
    this.maxConcurrentDatasourceRequests = 1;
    this.infiniteInitialRowCount = 1000;
    this.cacheBlockSize = 20,
    this.defaultColDef = {
      resizable: true,
      filter: true
    };
  }

  ngOnInit() {
    this.route.params.subscribe(
      value => {
        this.searchString = value['text']
        this.subject = value['subject']
        this.dataStore.getHeaders(this.subject).subscribe(ov => {
          this.initOverview(ov);
          this.setDataSource();
        })
      }
    );

  }

  apiService() {
    return this.dataStore;
  }

  initOverview(ov: Overview) {
    this.columnDefs = [];
    this.totalRows = undefined;

    if (ov===undefined) {
      return
    }
    
    this.subject = ov.subject;
    this.totalRows = new Number(ov.ttl);
    this.buildHeaders(ov.header)
  }

  buildRows(ov: Overview): any[] {
    
    if (ov.row===undefined || ov.row==null) {
      return [];
    }

    let rowData = []
    for (const r of ov.row) {
      let row: OvRowData = new OvRowData();
      row['link'] = '/' + ov.subject + '/' + r.id
      for (let i=0; i<ov.header.length; i++) {
        row[this.getFieldName(ov.header[i])] = r.c[i].v;
      }
      
      rowData.push(row);
    }

    

    return rowData;
  }

  getFieldName(col: OvColumn): string {
    return col.field.replace('.', '_');
  }

  buildHeaders(headers: OvColumn[]) {
    for (let i=0; i< headers.length; i++) {
      const c = headers[i]
      let col = {}
      switch (c.type) {
        case 3: case 4:
          col = { headerName: c.name, field: this.getFieldName(c), 
              valueFormatter: DateTimeFactory.dateTimeCellParser, width: 140,
              autoHeight: true, filter: "agDateColumnFilter" }
          break;
        default:
          col = { headerName: c.name, field: this.getFieldName(c), autoHeight: true, filterParams: {
            clearButton: true,
            applyButton: true
        } }
      }
      this.columnDefs.push(col)
    }
    this.columnDefs.unshift({ headerName: '', field: 'link', hide: true})
  }

  onRowDoubleClicked(event) {
    if (event.data.link !== undefined) {
      this.router.navigateByUrl(event.data.link);
    }
  }

  createNew() {
    const account = window.prompt("Input an account name:")
    if (account==null || account=="") {
      return
    }
    this.router.navigateByUrl(this.subject + '/' + account);
  }

  autoSizeAll(columnApi) {
    const allColumnIds = [];
    columnApi.getAllColumns().forEach(function(column) {
      if (column.colDef.valueFormatter === undefined) {
        allColumnIds.push(column.colId);
      }
    });
    columnApi.autoSizeColumns(allColumnIds);
  }

  onFilterModified(event) {
    if (this.gridApi!==undefined) {
      FilterFactory.fromFilterModel(this.gridApi.getFilterModel());
    }
  }

  onGridReady(params) {
    if (this.gridColumnApi===undefined) {
      this.gridColumnApi = params.columnApi;
    }
    if (this.gridApi===undefined) {
      this.gridApi = params.api;
      this.setDataSource();
    }
  }

  setDataSource() {
    if (this.gridApi===undefined) {
      return
    }
    this.gridApi.setDatasource({
        rowCount: null,
        getRows: (params: IGetRowsParams) => {
          this.apiService().getRows(this.subject, this.searchString, params.startRow, params.endRow - params.startRow).subscribe(ov => {
            const rowsThisPage = this.buildRows(ov);
            params.successCallback(rowsThisPage, ov.ttl);
          })
        }
      }
    );

    if (this.gridColumnApi!==undefined) {
      setTimeout(() => {
        this.autoSizeAll(this.gridColumnApi);
      }, 300);
    }
  }

}
