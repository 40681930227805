import { PartnerFactory } from './../partner/partner-factory';
import { CSVConverter } from './../csv-converter';
import { TimeTableFactory } from './../../timetable/timetable-factory';
import { TimeTable } from './../../model/timetable';
import { DateTimeFactory } from './../../model/date-factory';
import { Order } from './order';

export class OrderFactory {
  static fromObject(rawOrder: Order): Order {
    const result = rawOrder;
    result.created_at = DateTimeFactory.dateTimeValueParser(rawOrder.created_at);
    result.updated_at = DateTimeFactory.dateTimeValueParser(rawOrder.updated_at);
    result.order_date = DateTimeFactory.dateTimeValueParser(rawOrder.order_date);
    result.cd_earliest = DateTimeFactory.dateTimeValueParser(rawOrder.cd_earliest);
    result.cd_latest = DateTimeFactory.dateTimeValueParser(rawOrder.cd_latest);
    result.timetable = TimeTableFactory.fromObject(rawOrder.timetable);
    result.content = CSVConverter.text2array(rawOrder.content);
    result.customer = PartnerFactory.fromObject(rawOrder.customer);
    result.forwarder = PartnerFactory.fromObject(rawOrder.forwarder);
    result.supplier = PartnerFactory.fromObject(rawOrder.supplier);
    result.shipper = PartnerFactory.fromObject(rawOrder.shipper);
    result.factory = PartnerFactory.fromObject(rawOrder.factory);
    result.warehouse = PartnerFactory.fromObject(rawOrder.warehouse);
    result.final_customer = PartnerFactory.fromObject(rawOrder.final_customer);
    return result;
  }
}
