<div class="ui label" *ngIf="addressList.length>1">Addresses ({{addressList.length}})</div>
<div class="ui label" *ngIf="addressList.length<=1">Address</div>
<mat-tab-group>
  <mat-tab  *ngFor="let address of addressList" label="{{ address.type }}">
    <ng-template matTabContent>
      <table class="ui single line stackable very collapsing compact small table">
        <tbody>
          <tr>
            <td><b>Street</b></td>
            <td><b>Zip</b></td>
            <td><b>City</b></td>
            <td><b>Country</b></td>
          </tr>
          <tr>
            <td>
              <a *ngIf="address.street"
                  target="_blank" [routerLink]="['/map_address']"
                  [queryParams]="{ address: address.fullAddress }">
                {{ address.street }}
              </a>
            </td>
            <td>{{ address.zip}}</td>
            <td>{{ address.city}}</td>
            <td>{{ address.country}}</td>
          </tr>
        </tbody>
      </table>
    </ng-template>
  </mat-tab>
</mat-tab-group>
