
export class CSVConverter {
  static text2array(text: string): string[][] {
    const result = [];
    try {
        const lines = text.split(/\r\n|\r|\n/);
        for (let l = 0; l < lines.length - 1; l++) {
          const words = lines[l].split(';');
          result.push(words);
        }
        return result;
    } catch (err) {
      return [];
    }
  }
}
