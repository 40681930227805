<table *ngIf="partner" class="ui single line stackable very compact small table">
  <tbody>
    <tr>
      <td><b>Reference No.</b></td>
      <td>{{ partner.reference_no }}</td>
    </tr>
    <tr *ngIf="partner.partner_nr">
      <td><b>Partner No.</b></td>
      <td>{{ partner.partner_nr }}</td>
    </tr>
    <tr *ngIf="partner.short_name">
      <td><b>Name</b></td>
      <td>{{ partner.short_name }}</td>
    </tr>
    <tr *ngIf="partner.long_name">
      <td><b>Long Name</b></td>
      <td>{{ partner.long_name }}</td>
    </tr>
  </tbody>
</table>

<div class="ui row" *ngIf="partner && partner.address">
  <table class="ui single line stackable very collapsing compact small table">
    <tbody>
      <tr>
        <td><b>Street</b></td>
        <td><b>Zip</b></td>
        <td><b>City</b></td>
        <td><b>Country</b></td>
      </tr>
      <tr>
        <td>
          <a *ngIf="partner.address.street"
                  target="_blank" [routerLink]="['/map_address']"
                  [queryParams]="{ address: partner.address.fullAddress }">
                {{ partner.address.street }}
              </a>
        </td>
        <td>{{ partner.address.zip}}</td>
        <td>{{ partner.address.city}}</td>
        <td>{{ partner.address.country}}</td>
      </tr>
    </tbody>
  </table>
</div>
