import { Remark } from './../remark/remark';
import { Delivery } from './../order/delivery';
import { Container } from './../container/container';
import { TimeTable } from '../../model/timetable';
import { Partner } from '../../model/partner';
import { Address } from '../../model/address';

export class Booking {
  constructor(
    public uid: string,
    public reference_no: string,
    public version: number,
    public booking_no: string,
    public created_at: string,
    public updated_at: string,
    public status: string,
    public status_forwarder: string,
    public volume: number,
    public weight: number,
    public qty_pcs: number,
    public qty_ctn: number,
    public remark: Remark[],
    public timetable: TimeTable,
    public carrier: string,
    public vessel_airline: string,
    public container?: Container[],
    public delivery?: Delivery[],
    public address?: Address[],
    public customer?: Partner,
    public supplier?: Partner,
    public forwarder?: Partner,
    public warehouse?: Partner
  ) { }
}
