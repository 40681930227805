<div class="ui label" *ngIf="documentList!==undefined && documentList.length>1">Documents ({{documentList.length}})</div>
<div class="ui label" *ngIf="documentList!==undefined && documentList.length<=1">Document</div>

<mat-tab-group *ngIf="documentList!==undefined">
  <mat-tab  *ngFor="let document of documentList" label="Document {{ document.reference_no }}">
    <ng-template matTabContent>
      <table class="ui single line stackable very compact small table">
        <tbody>
          <tr>
            <td><b>UID</b></td>
            <td><b>Reference No.</b></td>
            <td><b>Number</b></td>
            <td><b>Type</b></td>
            <td><b>Date</b></td>
            <td><b>URL</b></td>
          <tr>
            <td>{{ document.uid }}</td>
            <td>{{ document.reference_no }}</td>
            <td>{{ document.number }}</td>
            <td>{{ document.type }}</td>
            <td>{{ document.date }}</td>
            <td><osca-download [uid]="uid" [docno]="document.reference_no" [name]="document.filename"></osca-download></td>
            </tr>
        </tbody>
      </table>
      <osca-partner [partner]="document.owner"></osca-partner>
    </ng-template>
  </mat-tab>
</mat-tab-group>
