
import {distinctUntilChanged, debounceTime} from 'rxjs/operators';
import { AuthData } from './../../auth/auth-data';
import { DataspacesService } from './../../dataspaces/dataspaces.service';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { Component, EventEmitter, Output, OnInit, Input, OnDestroy } from '@angular/core';
import { Overview } from '../../shared/search-result';


@Component({
  selector: 'osca-search-field',
  templateUrl: './search-field.component.html',
  styles: []
})
export class SearchFieldComponent implements OnInit, OnDestroy {

  keyup = new EventEmitter<string>();
  clientControl = new UntypedFormControl();

  subscriptionByKeyUp: Subscription;

  @Output() getResultsEvent = new EventEmitter<Overview>();
  searchParams: string;

  subjectList: string[];
  @Input() selectedSubject: string;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private dataspacesService: DataspacesService,
    private authData: AuthData) {
      this.subjectList = ['booking', 'shipment', 'order', 'quotation', 'account'];
    }

  ngOnInit() {
    this.route.params.subscribe(value => {
      if (value['text']===undefined) {
        this.searchParams = ''
      } else {
        this.searchParams = value['text']
      }
      if (value['subject']===undefined) {
        this.selectedSubject = ''
      } else {
        this.selectedSubject = value['subject']
      }
    });

    this.subscriptionByKeyUp = this.keyup.pipe(
      debounceTime(500),
      distinctUntilChanged(),)
      .subscribe( value => { this.router.navigateByUrl('/search/' + this.selectedSubject + '/' + value); });

    this.clientControl.setValue(this.selectedSubject);
    this.dataspacesService.currentDataspace
      .subscribe(activatedDataspace => this.onDataspaceChanged(activatedDataspace));
  }

  onDataspaceChanged(newDataspace: string) {
    if (newDataspace === '' || this.authData.getPartnerName() === newDataspace) {
      return;
    }
  }

  selectSubject(event: any, value: string) {
    if (event.source.selected) {
      this.selectedSubject = value;
      this.router.navigateByUrl('/search/' + this.selectedSubject + "/" + this.searchParams);
    }
  }

  ngOnDestroy() {
    this.subscriptionByKeyUp.unsubscribe();
  }

}
