import { PartnerFactory } from './../partner/partner-factory';
import { AddressFactory } from './../address/address-factory';
import { DeliveryFactory } from './../order/delivery-factory';
import { RemarkFactory } from './../remark/remark-factory';
import { TimeTableFactory } from './../../timetable/timetable-factory';
import { DateTimeFactory } from './../../model/date-factory';
import { TimeTable } from '../../model/timetable';
import { Booking } from './booking';
import { ContainerFactory } from '../container/container-factory';

export class BookingFactory {
  static fromList(bookingList: Booking[]): Booking[] {
    const result = new Array<Booking>();
    if (bookingList === undefined) {
      return result;
    }
    for (const rawBooking of bookingList) {
      result.push(BookingFactory.fromObject(rawBooking));
    }
    return result;
  }

  static fromObject(rawBooking: Booking): Booking {
    return new Booking(
      rawBooking.uid,
      rawBooking.reference_no,
      rawBooking.version,
      rawBooking.booking_no,
      DateTimeFactory.dateTimeValueParser(rawBooking.created_at),
      DateTimeFactory.dateTimeValueParser(rawBooking.updated_at),
      rawBooking.status,
      rawBooking.status_forwarder,
      rawBooking.volume,
      rawBooking.weight,
      rawBooking.qty_pcs,
      rawBooking.qty_ctn,
      RemarkFactory.fromList(rawBooking.remark),
      TimeTableFactory.fromObject(rawBooking.timetable),
      rawBooking.carrier,
      rawBooking.vessel_airline,
      ContainerFactory.fromList(rawBooking.container),
      DeliveryFactory.fromList(rawBooking.delivery),
      AddressFactory.fromList(rawBooking.address),
      PartnerFactory.fromObject(rawBooking.customer),
      PartnerFactory.fromObject(rawBooking.supplier),
      PartnerFactory.fromObject(rawBooking.forwarder),
      PartnerFactory.fromObject(rawBooking.warehouse)
    );
  }
}
