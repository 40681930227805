<div class="ui label" *ngIf="serviceList.length>1">Services ({{serviceList.length}})</div>
<div class="ui label" *ngIf="serviceList.length<=1">Service</div>
<table class="ui single line stackable very compact small table">
  <tbody>
    <tr>
      <td><b>Carrier</b></td>
      <td><b>Service</b></td>
      <td><b>Free Detention Days</b></td>
      <td><b>Free Demurrage Days</b></td>
    </tr>
    <tr *ngFor="let service of serviceList">
      <td>{{ service.carrier }}</td>
      <td>{{ service.service }}</td>
      <td>{{ service.freeDetentionDays }}</td>
      <td>{{ service.freeDemurrageDays }}</td>
    </tr>
  </tbody>
</table>
