import { Remark } from './../shared/remark/remark';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'osca-remark',
  templateUrl: './remark.component.html',
  styles: []
})
export class RemarkComponent {
  @Input() remarkList: Remark[];

  constructor() { }

}
