import { DateTimeFactory } from './../../model/date-factory';
import { WebhookInfo } from './webhook-info';

export class WebhookFactory {
  static fromList(whiList: WebhookInfo[]): WebhookInfo[] {
    const result = new Array<WebhookInfo>();
    if (whiList === null) {
      return result;
    }
    for (const wh of whiList) {
      result.push(new WebhookInfo(
          wh.service,
          wh.uid,
          wh.info,
          wh.content,
          DateTimeFactory.dateTimeValueParser(wh.timestamp),
          wh.ok
        ));
    }

    return result;
  }
}
