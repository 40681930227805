import { Thread } from '../shared/message/message';
import { Component, OnInit, Input } from '@angular/core';
import { MessageService } from '../shared/message/message.service';

@Component({
  selector: 'osca-message',
  templateUrl: './message.component.html',
  styles: []
})
export class MessageComponent implements OnInit {
  
  threadList: Thread[];
  @Input() subject: string;
  @Input() uid: string;

  constructor(private ms: MessageService) {  }

  ngOnInit(): void {
    this.ms.getThreadList(this.subject, this.uid).subscribe(
      threadList => this.threadList = threadList
    );
  }
}
