import { DateTimeFactory } from '../../model/date-factory';
import { TrackedContainer } from './tracking';

export class TrackingFactory {
  static fromList(containerList: TrackedContainer[]): TrackedContainer[] {
    if (containerList === undefined) {
      return null;
    }
    for (let i=0; i<containerList.length; i++) {
      for (let j=0; j<containerList[i].event.length; j++) {
        containerList[i].event[j].timestamp = DateTimeFactory.dateTimeValueParser(containerList[i].event[j].timestamp)
      };
    }
    return containerList;
  }
}
