import { AddressService } from './../shared/address/address.service';
import { DateTimeFactory } from './../model/date-factory';
import { Router, ActivatedRoute } from '@angular/router';
import { MapPosition } from './../shared/map/map-position';
import { Component, OnInit, Input } from '@angular/core';
import { icon, latLng, marker, polyline, tileLayer, MapOptions, TileLayer } from 'leaflet';
declare let L;

@Component({
  selector: 'osca-map-address',
  templateUrl: './map.component.html',
  styles: []
})

export class MapAddressComponent implements OnInit {

  markers: TileLayer[] = [];

  constructor( private as: AddressService, private activatedRoute: ActivatedRoute ) { }

  ngOnInit() {
    document.getElementById('weathermap').innerHTML = '<div id="map" style="width: 100%; height: 100%;"></div>';

    const map = new L.map('map', {
        zoom: 3,
        center: [18.8, 112.75],
        zoomControl: true
    });

    tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      detectRetina: true,
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    const params = this.activatedRoute.queryParams.subscribe(p => {
       this.as.getSingle(p['address'])
          .subscribe(pos => {
            const m = this.create(pos);
            if (m !== null) {
                map.setView(m.getLatLng(), map.getZoom());
                m.addTo(map);
                m.openPopup();
            }
          });
    });
    // this.test();
  }

  create(pos: MapPosition): any {
    if (pos === null) {
      return null;
    }
    const m = this.createMarker(pos);
    this.markers.push( m );
    return m;
  }

  createMarker(pos: MapPosition): any {
    const result = marker(latLng( pos.lat, pos.lon ), {
      icon: icon({
        iconSize: [ 25, 41 ],
        iconAnchor: [ 13, 41 ],
        iconUrl: 'leaflet/marker-icon.png',
        shadowUrl: 'leaflet/marker-shadow.png'})
    });
    result.bindPopup(pos.title);
    return result;
  }

  test() {
    // Marker for the top of Mt. Ranier
    const summit = marker([ 46.8523, -121.7603 ], {
      icon: icon({
        iconSize: [ 25, 41 ],
        iconAnchor: [ 13, 41 ],
        iconUrl: 'leaflet/marker-icon.png',
        shadowUrl: 'leaflet/marker-shadow.png'
      })
    });

    // Marker for the parking lot at the base of Mt. Ranier trails
    const paradise = marker([ 46.78465227596462, -121.74141269177198 ], {
      icon: icon({
        iconSize: [ 25, 41 ],
        iconAnchor: [ 13, 41 ],
        iconUrl: 'leaflet/marker-icon.png',
        shadowUrl: 'leaflet/marker-shadow.png'
      })
    });

    // Path from paradise to summit - most points omitted from this example for brevity
    const route = polyline([[ 46.78465227596462, -121.74141269177198 ],
      [ 46.80047278292477, -121.73470708541572 ],
      [ 46.815471360459924, -121.72521826811135 ],
      [ 46.8360239546746, -121.7323131300509 ],
      [ 46.844306448474526, -121.73327445052564 ],
      [ 46.84979408048093, -121.74325201660395 ],
      [ 46.853193528950214, -121.74823296256363 ],
      [ 46.85322881676257, -121.74843915738165 ],
      [ 46.85119913890958, -121.7519719619304 ],
      [ 46.85103829018772, -121.7542376741767 ],
      [ 46.85101557523012, -121.75431755371392 ],
      [ 46.85140013694763, -121.75727385096252 ],
      [ 46.8525277543813, -121.75995212048292 ],
      [ 46.85290292836726, -121.76049157977104 ],
      [ 46.8528160918504, -121.76042997278273 ]]);

      // this.options.layers.push(route, paradise, summit);
  }

}
