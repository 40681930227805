<table *ngIf="trackedContainer && trackedContainer.event" class="ui single line stackable very compact small table">
    <tbody>
      <tr>
        <td><b>Passed</b></td>
        <td><b>Status</b></td>
        <td><b>Place</b></td>
        <td><b>Timestamp</b></td>
        <td><b>Mot</b></td>
        <td><b>Voyage No.</b></td>
      </tr>
      <tr *ngFor="let event of trackedContainer.event">
        <td *ngIf="event.passed">&#10004;</td>
        <td *ngIf="!event.passed"></td>
        <td>{{ event.status }}</td>
        <td>{{ event.place }}</td>
        <td>{{ event.timestamp }}</td>
        <td>{{ event.mot }}</td>
        <td>{{ event.voyage_no }}</td>
      </tr>
    </tbody>
</table>
