import { ServiceResponse } from '../service-response';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { retry, catchError, map } from 'rxjs/operators';
import { Thread } from './message';
import { MessageFactory } from './message-factory';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  threadList: Thread[];

  constructor(private http: HttpClient) { }

  private errorHandler(error: Error | any): Observable<any> {
    return throwError(error);
  }

  getThreadList(subject, uid): Observable<Thread[]> {
    const endpoint = environment.host + environment.basePath + '/v1/dialog';
    return this.http
      .get<ServiceResponse>(`${endpoint}/email/all?subject=${subject}&uid=${uid}`)
      .pipe(
        retry(3),
        map(rsp => {
          if (rsp.payload === undefined || rsp.payload === null || rsp.payload.length < 1) {
            return null;
          }
          return MessageFactory.fromList(rsp.payload as Thread[]);
        } ),
        catchError(this.errorHandler),
      );
  }
}
