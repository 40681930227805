<div class="ui label">Customs Info</div>
<table class="ui single line stackable very compact small table">
  <tbody>
    <tr *ngIf="customsInfo.category">
      <td><b>Category</b></td>
      <td>{{ customsInfo.category }}</td>
    </tr>
    <tr *ngIf="customsInfo.customs_tariff_no">
      <td><b>Customs Tariff No.</b></td>
      <td>{{ customsInfo.customs_tariff_no }}</td>
    </tr>
    <tr *ngIf="customsInfo.country">
      <td><b>Country</b></td>
      <td>{{ customsInfo.country }}</td>
    </tr>
    <tr *ngIf="customsInfo.description">
      <td><b>Description</b></td>
      <td>{{ customsInfo.description }}</td>
    </tr>
  </tbody>
</table>
