import { MapPosition } from './../shared/map/map-position';
import { Subscription } from 'rxjs';
import { DateTimeFactory } from './../model/date-factory';
import { VesselRouteService } from './../shared/map/vessel-route.service';
import { ActivatedRoute } from '@angular/router';
import { ShipmentService } from './../shared/shipment/shipment.service';
import { Shipment } from './../shared/shipment/shipment';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'osca-shipment',
  templateUrl: './shipment.component.html',
  styles: []
})
export class ShipmentComponent implements OnInit, OnDestroy {

 shipment: Shipment;
 fromTo: string[];

 ssSubscription: Subscription;
 vrsSubscription: Subscription;

 posList: MapPosition[];

  constructor(
    private ss: ShipmentService,
    private vrs: VesselRouteService,
    private route: ActivatedRoute
  ) { }


  ngOnInit() {
    const params = this.route.snapshot.params;
    this.ssSubscription = this.ss.getSingle(params['uid']).subscribe(s => {
      this.shipment = s;
      this.fromTo = this.getVesselDates();
      this.vesselOnMap();
    });
  }

  ngOnDestroy() {
    this.ssSubscription.unsubscribe();
    if (this.vrsSubscription !== undefined) {
      this.vrsSubscription.unsubscribe();
    }
  }

  private vesselOnMap() {
    if (this.fromTo === null || this.shipment.vessel_airline === null) {
      return;
    }

    this.vrsSubscription = this.vrs.getRouteByVesselName(this.shipment.vessel_airline,
              DateTimeFactory.formatDateTime(this.fromTo[0], -7, 'DD.MM.YYYY'),
              DateTimeFactory.formatDateTime(this.fromTo[1], 7, 'DD.MM.YYYY'))
          .subscribe(posList => this.callVesselOnMap(posList));
  }

  private callVesselOnMap(posList: MapPosition[]) {
//    console.log(posList);
  }

  private getVesselDates(): string[] {
    if (this.shipment === undefined || this.shipment === null) {
      return null;
    }
    if (this.shipment.vessel_airline === undefined || this.shipment.vessel_airline === null) {
      return null;
    }
    if (this.shipment.timetable === undefined || this.shipment.timetable === null) {
      return null;
    }
    let etd = this.shipment.timetable.etd;
    if (etd === undefined || etd === null) {
      etd = this.shipment.timetable.eta;
    }
    if (etd === undefined || etd === null) {
      return null;
    }
    let eta = this.shipment.timetable.eta;
    if (eta === undefined || eta === null) {
      eta = etd;
    }
    return [etd, eta];
  }

}
