
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { WebhookFactory } from './webhook-factory';
import { ServiceResponse } from './../service-response';
import { HttpClient } from '@angular/common/http';
import { WebhookInfo } from './webhook-info';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { retry, map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WebhookService {
  private endpointInfo = environment.host + environment.basePath + '/v1/webhook/info';

  whInfoList: WebhookInfo[];

  constructor(private http: HttpClient) { }

  private errorHandler(error: Error | any): Observable<any> {
    return observableThrowError(error);
  }

  getAllInfo(subject, uid): Observable<WebhookInfo[]> {
    return this.http
      .get<ServiceResponse>(`${this.endpointInfo}?subject=${subject}&uid=${uid}`)
      .pipe(
        retry(3),
        map(rsp => {
          if (rsp.payload === null) {
            return null;
          }
          return rsp.payload;
        } ),
        map(rawWebhookInfo => WebhookFactory.fromList(rawWebhookInfo as WebhookInfo[])),
        catchError(this.errorHandler),
      );
  }

}
