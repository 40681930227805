export class MyEvent {
  constructor(
    public action: string,
    public timestamp: string,
    public uid: string,
    public status: string,
    public info: string,
    public content: string
  ) { }
}

