import { OverviewFilter, ColumnFilter, Condition } from './filter';

export class FilterFactory {
    static fromFilterModel(filterModel: {}): OverviewFilter {
        FilterFactory.printOverviewFilter(filterModel)
        return null
    }

    static printOverviewFilter(record: {}) {
        const keys = Object.keys(record)
        keys.forEach(key => {
            // console.log("column = ", key)
            const cf = FilterFactory.getColumnFilter(record[key]);
            cf.name = key;
            console.log(cf);
        });
    }

    static getColumnFilter(column: {}): ColumnFilter {
        const keys = Object.keys(column)
        let result = ColumnFilter.create();
        keys.forEach(key => {
            if (key.startsWith("condition")) {
                result.addCondition(FilterFactory.getCondition(column[key]))
            } else if (key=="operator") {
                result.op = column[key]
            } else if (keys.indexOf("operator") < 0) {
                const c = result.getCondition();
                c.addParam(key, column[key])
            }
        });
        return result;
    }

    static getCondition(column: {}): Condition {
        const keys = Object.keys(column)
        let result = Condition.create();
        keys.forEach(key => {
            result.addParam(key, column[key])
        });
        return result;
    }
}
  