<div class="ui label">Partners</div>
<mat-tab-group>
  <mat-tab  *ngIf="customer" label="Customer">
    <ng-template matTabContent>
      <osca-partner [partner]="customer"></osca-partner>
    </ng-template>
  </mat-tab>
  <mat-tab  *ngIf="supplier" label="Supplier">
    <ng-template matTabContent>
      <osca-partner [partner]="supplier"></osca-partner>
    </ng-template>
  </mat-tab>
  <mat-tab  *ngIf="factory" label="Factory">
    <ng-template matTabContent>
      <osca-partner [partner]="factory"></osca-partner>
    </ng-template>
  </mat-tab>
  <mat-tab  *ngIf="forwarder" label="Forwarder">
    <ng-template matTabContent>
      <osca-partner [partner]="forwarder"></osca-partner>
    </ng-template>
  </mat-tab>
  <mat-tab  *ngIf="warehouse" label="Warehouse">
    <ng-template matTabContent>
      <osca-partner [partner]="warehouse"></osca-partner>
    </ng-template>
  </mat-tab>
  <mat-tab  *ngIf="final_customer" label="Final Customer">
    <ng-template matTabContent>
      <osca-partner [partner]="final_customer"></osca-partner>
    </ng-template>
  </mat-tab>
</mat-tab-group>
