<div class="ui padded grid">
  <div class="row" *ngIf="showLogo">
    <h2 class="ui teal image header centered">
      <img src="assets/images/logo.png" class="image">
      <div class="content">Online Supply Chain Accelerator</div>
    </h2>
  </div>
</div>

<osca-search-field class="streched centered row"></osca-search-field>

<osca-search-result *ngIf="!showLogo"></osca-search-result>
