import { Subscription } from 'rxjs';
import { SearchFieldComponent } from './../search-field/search-field.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DataStoreService } from './../../shared/data-store.service';
import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'osca-search-frame',
  templateUrl: './search-frame.component.html',
  styles: []
})
export class SearchFrameComponent implements OnInit, OnDestroy {

  subscription: Subscription;

  showLogo: boolean;

  @ViewChild(SearchFieldComponent, {static: true}) searchField: SearchFieldComponent;
  constructor(private route: ActivatedRoute) {
    this.showLogo = true;
  }

  ngOnInit() {
    this.subscription = this.route.params.subscribe(
      value => this.showLogo = (value['text'] === undefined || value['text'] === '') && (value['subject'] === undefined)
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
