<div class="ui middle aligned center aligned grid">
  <div class="column">
    <h2 class="ui purple image header">
      <img src="assets/images/logo.png" class="image">
      <div class="content">
        Log-in to your account
      </div>
    </h2>
    <form class="ui large form" [formGroup]="authForm" (ngSubmit)="login()">
      <div class="ui stacked segment">
        <div class="field">
          <div class="ui left icon input">
            <i class="user icon"></i>
            <input formControlName="username" type="text" placeholder="Username">
          </div>
        </div>

        <div class="field">
            <div class="ui left icon input">
              <i class="lock icon"></i>
              <input formControlName="password" type="password" placeholder="Password">
            </div>
        </div>
        <div class="ui negative message" *ngIf="wrongPasswordIsEntered">
          <i class="close icon" (click)="closeErrorMessage()"></i>
          <div class="header">
            Username or Password is wrong!
          </div>
          <p>Please check your credentials or contact support.</p></div>

        <button class="ui fluid large purple submit button" type="submit">Login</button>
      </div>
    </form>
    <div class="ui message">
      Version: {{ version }}
    </div>
<!--
    <div class="ui message">
      New to us? <a href="#">Sign Up</a>
    </div>
-->
  </div>
</div>
