import { QuotationPosition } from './../shared/quotation/quotation-position';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'osca-quotation-position',
  templateUrl: './quotation-position.component.html',
  styles: []
})
export class QuotationPositionComponent {
  @Input() posList: QuotationPosition[];

  constructor() { }

}
