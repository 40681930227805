import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataspacesService {
  private dataspaceSource = new BehaviorSubject('');

  currentDataspace = this.dataspaceSource.asObservable();

  constructor() { }

  onDataspaceChanged(newDataspace: string) {
    this.dataspaceSource.next(newDataspace);
  }
}
