import { environment } from '../../../environments/environment';
import { ServiceResponse } from '../service-response';
import { throwError, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retry, catchError, map } from 'rxjs/operators';
import { TrackedContainer } from './tracking';
import { TrackingFactory } from './tracking-factory';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  private endpointInfo = environment.host + environment.basePath + '/v1/tracking';

  containerList: TrackedContainer[];

  constructor(private http: HttpClient) { }

  private errorHandler(error: Error | any): Observable<any> {
    return throwError(() => error);
  }

  getAllInfo(shipmentUID, containerNo): Observable<TrackedContainer> {
    return this.http
      .get<ServiceResponse>(`${this.endpointInfo}/containertracking?shipment_uid=${shipmentUID}&container_no=${containerNo}`)
      .pipe(
        retry(3),
        map(rsp => {
          if (rsp.payload === null) {
            return null;
          }
          return rsp.payload;
        } ),
        map(containerList => TrackingFactory.fromList(containerList as TrackedContainer[])),
        map(containerList => (containerList.length) > 0 ? containerList[0] : null),
        catchError(this.errorHandler),
      );
  }
}
