import { Component, OnInit, Input } from '@angular/core';
import { TrackedContainer } from '../shared/tracking/tracking';
import { TrackingService } from '../shared/tracking/tracking.service';

@Component({
  selector: 'osca-tracking',
  templateUrl: './tracking.component.html',
  styles: []
})
export class TrackingComponent implements OnInit {
  @Input() shipmentUID: string;
  @Input() containerNo: string;
  trackedContainer: TrackedContainer;

  constructor(private trackingService: TrackingService) { }

  ngOnInit() {
    this.trackingService.getAllInfo(this.shipmentUID, this.containerNo).subscribe(
      containerData => this.trackedContainer = containerData
    );
  }

}
