  <div style="width:75%;  margin-left: 50px">

    <div class="ui grid">
      <div class="four wide column">
        <mat-form-field>
        <mat-label>-- Select subject --</mat-label>
        <mat-select matInput [formControl]="clientControl" >
          <mat-option *ngFor="let item of subjectList" [value]="item" #subject (onSelectionChange)="selectSubject($event, item)">{{item}}</mat-option>
        </mat-select>
        </mat-form-field>
      </div>
      <div class="twelve wide streched column">
        <form class="ui large form">
          <div class="ui right icon input"  style="width:100%">
            <i class="search icon"></i>
            <input (keyup.enter)="keyup.emit(searchTerm.value)" #searchTerm type="text" value="{{ searchParams }}">
          </div>
        </form>
      </div>
    </div>
  </div>
