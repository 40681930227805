import { AcknowledgementService } from './../shared/acknowledgement/acknowledgement.service';
import { Acknowledgement } from './../shared/acknowledgement/acknowledgement';
import { MyEvent } from './my-event';
import { WebhookInfo } from './../shared/webhook/webhook-info';
import { WebhookService } from './../shared/webhook/webhook.service';
import { Component, OnInit, Input, Output } from '@angular/core';

@Component({
  selector: 'osca-event-list',
  templateUrl: './event-list.component.html',
  styles: []
})

export class EventListComponent implements OnInit {
  @Input() subject: string;
  @Input() uid: string;

  eventList = new Array<MyEvent>();

  constructor(
    private whService: WebhookService,
    private acknowledgementService: AcknowledgementService
  ) { }

  ngOnInit() {
    this.whService.getAllInfo(this.subject, this.uid).subscribe(whiList => this.showWebhookEvents(whiList));
    this.acknowledgementService.getAcknowledgement(this.subject, this.uid).subscribe(
      acknowledgement => this.showAcknowledgementEvents(acknowledgement)
    );
  }

  private showWebhookEvents(whiList: WebhookInfo[]) {
    for (const whi of whiList) {
      const event = new MyEvent(
        'Uploaded',
        whi.timestamp,
        whi.uid,
        whi.ok ? 'success' : 'failed',
        whi.info,
        whi.content
      );
      this.eventList.push(event);
    }
  }

  private showAcknowledgementEvents(acknowledgement: Acknowledgement) {
    if (acknowledgement === null || acknowledgement === undefined) {
      return;
    }
    for (const ack of acknowledgement.consumers) {
      if (ack.acknowledgement === '') {
        continue;
      }
      const event = new MyEvent(
          'Acknowledgement',
          ack.acknowledgement,
          acknowledgement.uid,
          'ok',
          'by ' + ack.consumer + ' (' + ack.role + ')',
          'since ' + acknowledgement.timestamp
        );
      this.eventList.push(event);
    }
  }

}
