
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {map, retry, catchError} from 'rxjs/operators';
import { MapPositionFactory } from './map-position-factory';
import { ServiceResponse } from './../service-response';
import { HttpClient } from '@angular/common/http';
import { MapPosition, MapPositionRaw } from './map-position';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VesselRouteService {
  private endpointRoute = environment.host + environment.basePath + '/v1/tracking/route';
  private endpointPosition = environment.host + environment.basePath + '/v1/tracking/position';

  posList: MapPosition[];

  constructor(private http: HttpClient) { }

  private errorHandler(error: Error | any): Observable<any> {
    return observableThrowError(error);
  }

  getRouteByVesselName(name, from, to): Observable<MapPosition[]> {
    return this.http
      .get<ServiceResponse>(`${this.endpointRoute}?name=${name}&from=${from}&to=${to}`).pipe(
      retry(3),
      map(rsp => rsp.payload === undefined ? null : rsp.payload ),
      map(rawPos => {
        this.posList = MapPositionFactory.fromListForVessel(name, rawPos as MapPositionRaw[]);
        return this.posList;
       } ),
      catchError(this.errorHandler),);
  }

  getPositionByVesselName(name): Observable<MapPosition[]> {
    return this.http
      .get<ServiceResponse>(`${this.endpointPosition}?name=${name}`).pipe(
      retry(3),
      map(rsp => rsp.payload === undefined ? null : rsp.payload ),
      map(rawPos => {
        this.posList = MapPositionFactory.fromListForVessel(name,
          new Array<MapPositionRaw>(rawPos as any)
        );
        return this.posList;
       } ),
      catchError(this.errorHandler),);
  }

}
