import { Address } from './../model/address';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'osca-address',
  templateUrl: './address.component.html',
  styles: []
})
export class AddressComponent implements OnInit {
  @Input() addressList: Address[];

  constructor() { }

  ngOnInit() {
  }

}
