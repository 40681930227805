import { Router, ActivatedRoute } from '@angular/router';
import { Document } from '../shared/document/document';
import { Component, OnInit, Input } from '@angular/core';
import { DownloadService } from './download.service';

@Component({
  selector: 'osca-download',
  templateUrl: './download.component.html',
  styles: []
})

export class DownloadComponent {
  @Input() docno: string;
  @Input() uid: string;
  @Input() name: string;

  constructor( 
    private ds: DownloadService) { }

  onClick() {
    this.ds.getSingle(this.uid, this.docno).subscribe((resultBlob: Blob) => {
      var link = document.createElement('a');
      link.href = URL.createObjectURL(resultBlob);
      link.download = this.name;
      link.click();
    }
    )
  }

}
