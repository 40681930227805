import { OrderRoute, OrderRoutePoint } from './order-route-point';
import { DateTimeFactory } from './../../model/date-factory';
import { MapPosition, MapPositionRaw } from './map-position';
import { GoogleRoute, GooglePoint } from './google-api';
import * as moment from 'moment';

export class MapPositionFactory {
  static fromListForVessel(vesselName: string, posList: MapPositionRaw[]): MapPosition[] {
    if (posList === undefined || posList === null) {
      return null;
    }
    let lastLon = 0;
    let shiftLon = 0;
    const result = new Array<MapPosition>();
    for (const rawPos of posList) {
      let lon = Number(rawPos.LON)
      if (lastLon>0 && lon < -90) {
        shiftLon = 360
      }
      const p = new MapPosition(
        Number(rawPos.LAT),
        lon + shiftLon,
        vesselName,
        0,
        DateTimeFactory.dateTimeValueParser(rawPos.TIMESTAMP),
        rawPos.is_fake
      )
      result.push(p);
      lastLon = lon
    }
    return result;
  }

  static fromListForOrder(orderRoute: OrderRoute): MapPosition[] {

    orderRoute.route.sort(function(a, b) { return a.timestamp > b.timestamp ? 1 : -1; });

    let model = { lastLon: 0, shiftLon: 0}
    let result = MapPositionFactory.fromListForOrderAndChain(orderRoute, 0, model)
      .concat(MapPositionFactory.fromListForOrderAndChain(orderRoute, 1, model))
      .concat(MapPositionFactory.fromListForOrderAndChain(orderRoute, 2, model))
    ;
    return result;
  }

  static fromListForOrderAndChain(orderRoute: OrderRoute, chainIndex: number, model: {lastLon: Number, shiftLon: Number}): MapPosition[] {
    const result = new Array<MapPosition>();
    for (const rawPos of orderRoute.route) {
      if (rawPos.coordinate === null) {
        continue;
      }

      let ok = (rawPos.event === 'On Board' && chainIndex == 1);
      ok = ok || ((rawPos.event === 'ETA' || rawPos.event.startsWith('Delivery')) && chainIndex == 2);
      ok = ok || (rawPos.event !== 'On Board' && rawPos.event !== 'ETA' && !rawPos.event.startsWith('Delivery')) && (chainIndex == 0);

      if (ok) {

        let lon = Number(rawPos.coordinate.lon) + model.shiftLon.valueOf();

        if (model.lastLon.valueOf() - lon - model.shiftLon.valueOf() > 180) {
          model.shiftLon = model.shiftLon.valueOf() + 360;
          lon += 360
        }

        result.push(new MapPosition(
          Number(rawPos.coordinate.lat),
          lon,
          MapPositionFactory.getInfoForOrder(rawPos),
          chainIndex,
          rawPos.timestamp,
          rawPos.is_fake
        ));
        model.lastLon = lon
      }
    }
    return result
  }

  static createPoint(gp: GooglePoint, lonHelper: {lastLon: Number, shiftLon: Number}): MapPosition {
    let lon = Number(gp.lng)
    if (lonHelper.lastLon !== null && lonHelper.lastLon.valueOf() > 0 && lon < -90) {
      lonHelper.shiftLon = 360
    }
    lonHelper.lastLon = lon

    return new MapPosition(
      Number(gp.lat),
      lon + lonHelper.shiftLon.valueOf(),
      "",
      0,
      "",
      false
    );
}

  static fromGoogleRoute(etd: string, routeList: GoogleRoute[]): MapPosition[] {
    if (routeList === undefined || routeList === null) {
      return null;
    }

    let lonHelper = { lastLon: 0, shiftLon: 0}
    const result = new Array<MapPosition>();

    const startAtClock = 8; // trucker starts at 8 o'clock
    const driveHours = 10; // and drives 10 hours every day

    let meters = 0
    let seconds = startAtClock*60*60
    const start_time = moment(etd, 'YYYY-MM-DD HH:mm')
    
    for (const r of routeList) {
      for (const l of r.legs) {
        for (const s of l.steps) {
          let current_time = start_time.clone()
          if (seconds>(startAtClock + driveHours)*60*60) {
            seconds -= driveHours*60*60;
            start_time.add(1, 'days');
          }

          seconds += s.duration.value

          let p = MapPositionFactory.createPoint(s.end_location, lonHelper)
          p.title = '' + Math.round(meters / 1000) + ' km'
          p.timestamp = current_time.add(seconds, 'seconds').format('DD.MM.YYYY HH:mm')
          result.push(p)

          meters += s.distance.value
        }
      }
    }
    return result;
  }

  private static getInfoForOrder(pos: OrderRoutePoint): string {
    let result = '';
    if (pos.event !== '' && pos.timestamp !== undefined && pos.timestamp !== null) {
      result += pos.event + ': ' + DateTimeFactory.dateTimeValueParser(pos.timestamp);
    }
    if (pos.qty > 0) {
      result += '<br>Qty: ' + pos.qty;
    }
    if (pos.info !== '') {
      result += '<br>' + pos.info;
    };

    return result;
  }
}
