import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Transaction } from '../shared/transaction/transaction';
import { Subscription } from 'rxjs';
import { TransactionService } from '../shared/transaction/transaction.service';
import {throwError as observableThrowError,  Observable } from 'rxjs';
import {retry, catchError} from 'rxjs/operators';

@Component({
  selector: 'osca-transaction',
  templateUrl: './transaction.component.html',
  styles: [ ]
})
export class TransactionComponent implements OnInit, OnDestroy {

  @Input() subject: string;
  @Input() uid: string;

  transactionList: Transaction[];
  trnSubscription: Subscription;

  private errorHandler(error: Error | any): Observable<any> {
    return observableThrowError(error);
  }
  
  constructor(
    private ts: TransactionService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.trnSubscription = this.ts.getAll(this.subject, this.uid).pipe(
      retry(3),
      catchError(this.errorHandler)).subscribe(transactionList => {
      this.transactionList = transactionList;
    });
  }

  ngOnDestroy() {
    if (this.trnSubscription !== undefined) {
      this.trnSubscription.unsubscribe();
    }
  }

  onRetrigger(command: string, hash: number) {
    this.ts.reTrigger(this.subject, this.uid, command, hash)
  }
  
}
