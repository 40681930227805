import { AcknowledgementFactory } from './acknowledgement-factory';
import { Acknowledgement } from './acknowledgement';
import { ServiceResponse } from './../service-response';
import { throwError, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { retry, catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AcknowledgementService {

  acknowledgement: Acknowledgement;

  constructor(private http: HttpClient) { }

  private errorHandler(error: Error | any): Observable<any> {
    return throwError(() => error);
  }

  getAcknowledgement(subject, uid): Observable<Acknowledgement> {
    const endpoint = environment.host + environment.basePath + '/v1/' + subject + '/acknowledgement';
    return this.http
      .get<ServiceResponse>(`${endpoint}?uid=${uid}`)
      .pipe(
        retry(3),
        map(rsp => {
          if (rsp.payload === undefined || rsp.payload === null || rsp.payload.length < 1) {
            return null;
          }
          return AcknowledgementFactory.fromObject(rsp.payload[0] as Acknowledgement);
        } ),
        catchError(this.errorHandler),
      );
  }
}
