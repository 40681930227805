import { PartnerFactory } from './../partner/partner-factory';
import { TimeTable } from './../../model/timetable';
import { Shipment } from './shipment';
import { DateTimeFactory } from './../../model/date-factory';
import { TimeTableFactory } from '../../timetable/timetable-factory';
import { RemarkFactory } from '../remark/remark-factory';
import { ContainerFactory } from '../container/container-factory';

export class ShipmentFactory {
  static fromObject(rawShipment: Shipment): Shipment {
    return new Shipment(
      rawShipment.uid,
      rawShipment.reference_no,
      rawShipment.version,
      DateTimeFactory.dateTimeValueParser(rawShipment.created_at),
      DateTimeFactory.dateTimeValueParser(rawShipment.updated_at),
      rawShipment.status,
      rawShipment.status_forwarder,
      TimeTableFactory.fromObject(rawShipment.timetable),
      rawShipment.carrier,
      rawShipment.vessel_airline,
      RemarkFactory.fromList(rawShipment.remark),
      rawShipment.document,
      ContainerFactory.fromList(rawShipment.container),
      PartnerFactory.fromObject(rawShipment.customer),
      PartnerFactory.fromObject(rawShipment.forwarder)
    );
  }
}
