import { Injectable } from '@angular/core';
import * as decode from 'jwt-decode';

class DataSpace {
  constructor (
    public name: string,
    public consumer: string,
    public uid: string,
    public origin: string
  ) { }
}

class TokenData {
  constructor(
    public consumer: string,
    public exp: string,
    public role: string,
    public spaces: Array<DataSpace>
  ) { }
}

@Injectable()
export class AuthData {

    constructor() { }

    getToken(): string {
        return this.getValue('token');
    }

    setToken(value: any): void {
      this.setValue('token', value);
    }

    removeToken(): void {
      this.removeValue('partner_id');
      this.removeValue('token');
    }

    isEmpty(): boolean {
      return this.isValueEmpty('token');
    }

    private isValueEmpty(name: string): boolean {
      return localStorage.getItem(name) === undefined || localStorage.getItem(name) === '';
    }

    isExpired(): boolean {
      if (this.getTokenData() === null) {
        return true;
      }

      const jsonData = this.getTokenData();

      const expiresAt = this.getExpirationDate(jsonData.exp);

      const date = this.getExpirationDate(expiresAt);
      if (date === undefined) {
        return true;
      }
      return !(date.valueOf() > new Date().valueOf());
    }

    private getExpirationDate(exp: any) {
      const date = new Date(0);
      date.setUTCSeconds(exp);
      return date;
    }

    getPartnerID(): string {
      return this.getValue('partner_id');
    }

    selectSpace(name: string) {
      const spaces = this.getSpaces();
      for (let i = 0; i < spaces.length; i++) {
        if (spaces[i].name === name) {
          this.setPartnerID(spaces[i].uid);
        }
      }
    }

    getPartnerName(): string {
      const partnerID = this.getPartnerID();
      if (partnerID === undefined) {
        return undefined;
      }

      const spaces = this.getSpaces();
      for (let i = 0; i < spaces.length; i++) {
        if (spaces[i].uid === partnerID) {
          return spaces[i].name;
        }
      }
      return undefined;
    }

    private setPartnerID(uid: string) {
      this.setValue('partner_id', uid);
    }

    private getTokenData(): TokenData {
      if (this.getToken() === null) {
        return null;
      }
      const jsonData: TokenData = decode(this.getToken());
      return jsonData;
    }

    getSpaces(): Array<DataSpace> {
      return this.getTokenData().spaces;
    }

    getUsername(): string {
      return this.getTokenData().consumer;
    }

    private getValue(name: string): any {
      return localStorage.getItem(name);
    }

    private setValue(name: string, value: any) {
      return localStorage.setItem(name, value);
    }

    private removeValue(name: string) {
      return localStorage.removeItem(name);
    }
}
