import { DateTimeFactory } from './../../model/date-factory';
import { Acknowledgement, EventConsumer } from './acknowledgement';

export class AcknowledgementFactory {
  static fromObject(rawAcknowledgement: Acknowledgement): Acknowledgement {
    return new Acknowledgement(
      rawAcknowledgement.uid,
      AcknowledgementFactory.fromConsumerList(rawAcknowledgement.consumers),
      rawAcknowledgement.action,
      DateTimeFactory.dateTimeValueParser(rawAcknowledgement.timestamp)
    );
  }

  static fromConsumerList(eventConsumerList: EventConsumer[]): EventConsumer[] {
    const result = new Array<EventConsumer>();
    if (eventConsumerList === undefined) {
      return result;
    }
    for (const eventConsumer of eventConsumerList) {
      result.push(new EventConsumer(
        eventConsumer.consumer,
        eventConsumer.role,
        DateTimeFactory.dateTimeValueParser(eventConsumer.acknowledgement)
      ));
    }
    return result;
  }

}
