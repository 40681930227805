<form class="ui large form" [formGroup]="partnerForm" (ngSubmit)="submitPartnerConsumer()">
    <h2>Partners</h2>
    <table cellspacing="0" formArrayName="partnerList">
          <thead>
          <th>ID</th>
          <th>Account</th>
          <th><button class="ui small button"> <mat-icon matSuffix>save</mat-icon> </button></th>
          </thead>
          <tbody>
        <tr *ngFor="let partner of partnerList.controls; index as i; last as l" [formGroupName]="i">  
          <td><input type='text' formControlName="id"></td>
          <td><input type='text' formControlName="consumer"></td>
          <td><button *ngIf="l" mat-button (click)="addPartnerConsumer()" class="ui small button"> <mat-icon matSuffix>add</mat-icon> </button></td> 
        </tr>
        </tbody>
  </table>
  </form>