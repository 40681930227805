
import {catchError, map, retry} from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AuthData } from './../auth/auth-data';
import { Overview } from './search-result';
import { ServiceResponse } from './service-response';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


import {environment} from '../../environments/environment';

@Injectable()
export class DataStoreService {
  private endpoint = environment.host + environment.basePath + '/v1/search/result';

  constructor(private http: HttpClient, private authData: AuthData) { }

  private errorHandler(error: Error | any): Observable<any> {
    return throwError(() => error);
  }

  getHeaders(subject: string): Observable<Overview> {
    const partnerID = this.authData.getPartnerID();
    return this.http
      .get<ServiceResponse>(`${this.endpoint}/${subject}?partner_id=${partnerID}`).pipe(
      retry(1),
      map(rsp => {
        return rsp.payload === undefined ? null : rsp.payload as any;
       } ),
      map(response => response as Overview ),
      catchError(this.errorHandler),);
  }

  getRows(subject, searchTerm: string, offset, page: number): Observable<Overview> {
    if (searchTerm === undefined)
      searchTerm = '';
    if (searchTerm == '' && subject===undefined) {
      return new Observable<Overview>();
    }
    const partnerID = this.authData.getPartnerID();
    return this.http
      .get<ServiceResponse>(`${this.endpoint}/${subject}?partner_id=${partnerID}&query=${searchTerm}&offset=${offset}&page=${page}`).pipe(
      retry(1),
      map(rsp => {
        return rsp.payload === undefined ? null : rsp.payload as any;
       } ),
      map(response => response as Overview ),
      catchError(this.errorHandler),);
  }

}
