
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map, retry} from 'rxjs/operators';
import { OrderRoute } from './order-route-point';
import { MapPositionFactory } from './map-position-factory';
import { ServiceResponse } from './../service-response';
import { HttpClient } from '@angular/common/http';
import { MapPosition, MapPositionRaw } from './map-position';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OrderRouteService {
  private endpoint = environment.host + environment.basePath + '/v1/order/route';

  posList: MapPosition[];

  constructor(private http: HttpClient) { }

  private errorHandler(error: Error | any): Observable<any> {
    return observableThrowError(error);
  }

  getOrderRouteByUID(uid): Observable<MapPosition[]> {
    return this.http
      .get<ServiceResponse>(`${this.endpoint}?uid=${uid}`).pipe(
      retry(3),
      map(rsp => rsp.payload === undefined ? null : rsp.payload ),
      map(rawPos => {
        this.posList = MapPositionFactory.fromListForOrder(rawPos as any);
        return this.posList;
       } ),
      catchError(this.errorHandler),);
  }

}
