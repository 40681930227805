<div class="ui label" *ngIf="eventList.length>1">Events ({{eventList.length}})</div>
<div class="ui label" *ngIf="eventList.length==1">Event</div>
<table class="ui single line stackable very compact small table" *ngIf="eventList.length>0">
  <tbody>
    <tr>
      <td><b>Action</b></td>
      <td><b>Timestamp</b></td>
      <td><b>UID</b></td>
      <td><b>Status</b></td>
      <td><b>Info</b></td>
    </tr>
    <tr *ngFor="let event of eventList">
      <td>{{ event.action }}</td>
      <td>{{ event.timestamp }}</td>
      <td>{{ event.uid }}</td>
      <td>{{ event.status }}</td>
      <td>{{ event.info }}</td>
    </tr>
  </tbody>
</table>
