import { DateTimeFactory } from './../../model/date-factory';
import { Remark } from './remark';

export class RemarkFactory {
  static fromList(remarkList: Remark[]): Remark[] {
    if (remarkList === undefined) {
      return null;
    }
    const result = new Array<Remark>();
    for (const rawRemark of remarkList) {
      result.push(new Remark(
        rawRemark.reference_no,
        DateTimeFactory.dateTimeValueParser(rawRemark.created_at),
        rawRemark.created_by,
        rawRemark.content
      ));
    }
    return result;
  }
}
