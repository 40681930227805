import { Component, OnInit } from '@angular/core';
import { AccountService } from '../shared/account/account.service';
import { ActivatedRoute } from '@angular/router';
import { Account } from '../shared/account/account';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'osca-account',
  templateUrl: './account.component.html',
  styles: []
})
export class AccountComponent implements OnInit {
  account: Account;
  errors: { [key: string]: string} = { };
  isUpdatingAccount = false;
  accountForm: UntypedFormGroup;
  hideAPIkey = true;
  messages: string[] = [];
  gatewayError: string;

  constructor(
    private as: AccountService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    const params = this.route.snapshot.params;
    if (params['consumer']) {
      this.as.getAccount(params['consumer']).subscribe(a => {
        if (a==null)
          a = new Account(params['consumer'], '', 'FORWARDER', false, '', '', '', []);
        this.initAccount(a)
      });
    }
  }

  initAccount(a: Account) {
    this.account = a;

    if (this.account.user===undefined) {
      this.account.user = [];
    }

    this.isUpdatingAccount = (this.account.origin!='');
    
    console.log(this.account)

    this.accountForm = this.fb.group({
      consumer: [{value: this.account.consumer, disabled: this.isUpdatingAccount}],
      origin: [{value: this.account.origin, disabled: this.isUpdatingAccount}],
      role: this.account.role,
      name: this.account.name,
      apikey: this.account.apikey,
      gateway_error: this.account.gateway_error
    })
    this.accountForm.statusChanges.subscribe(() => this.updateErrorMessages());
    this.gatewayError = this.account.gateway_error;
  }

  updateErrorMessages() {

  }

  generateAPIKey() {
    if (this.account.apikey.length>0) {
      if (!window.confirm('Do you really want to generate a new api key? The old key will not be destroyed until it is deployed in the gateway')) {
        return;
      }
    }

    this.as.generateAPIKey(this.account.consumer).subscribe(apikey => 
      {
        this.account.apikey = apikey;
        this.accountForm.patchValue({"apikey": apikey});
      });
  }

  submitAccount() {

    const a = this.accountForm.value

    if (a.origin!==undefined) {
      this.account.origin = a.origin;
    }
    this.account.role = a.role;
    this.account.name = a.name;

    if (this.account.origin.length<2 && this.account.origin.length>5) {
      window.alert('Origin length must be between 2 and 5');
      return;
    }

    this.as.postAccount(this.account).subscribe(msg => {
      this.messages.push('Account saved')
    });

    if (!this.isUpdatingAccount) {
      window.location.reload();
    }
  }
}
