
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {retry, map, catchError} from 'rxjs/operators';
import { ShipmentFactory } from './shipment-factory';
import { ServiceResponse } from './../service-response';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Shipment } from './shipment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ShipmentService {
  private endpoint = environment.host + environment.basePath + '/v1/shipment';

  shipmentList: Shipment[];

  constructor(private http: HttpClient) { }

  private errorHandler(error: Error | any): Observable<any> {
    return observableThrowError(error);
  }

  getSingle(uid): Observable<Shipment> {
    return this.http
      .get<ServiceResponse>(`${this.endpoint}?uid=${uid}`).pipe(
      retry(3),
      map(rsp => rsp.payload[0] ),
      map(rawShipment => ShipmentFactory.fromObject(rawShipment as Shipment)),
      catchError(this.errorHandler),);
  }

  getAll(uid): Observable<Array<Shipment>> {
    return this.http
      .get<ServiceResponse>(`${this.endpoint}?uid=${uid}`).pipe(
      retry(3),
      map(response => response.payload),
      catchError(this.errorHandler),);
  }
}
