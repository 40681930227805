import { Address } from './address';

export class Partner {
  constructor(
    public uid: string,
    public reference_no: string,
    public partner_nr: string,
    public short_name: string,
    public long_name: string,
    public address?: Address
  ) { }
}
