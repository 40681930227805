import { Price } from './../../shared/order/price';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'osca-price',
  templateUrl: './price.component.html',
  styles: []
})
export class PriceComponent implements OnInit {
  @Input() price: Price;

  constructor() { }

  ngOnInit() {
  }

}
