import { OrderRouteService } from './../shared/map/order-route.service';
import { DateTimeFactory } from './../model/date-factory';
import { Router, ActivatedRoute } from '@angular/router';
import { MapPosition } from './../shared/map/map-position';
import { Component, OnInit, Input } from '@angular/core';
import { icon, latLng, marker, polyline, tileLayer, MapOptions, TileLayer } from 'leaflet';
declare let L;

@Component({
  selector: 'osca-map-order',
  templateUrl: './map.component.html',
  styles: []
})

export class MapOrderComponent implements OnInit {
  markers: TileLayer[] = [];
  lastMarker: L.Marker;

  constructor( private ors: OrderRouteService, private activatedRoute: ActivatedRoute ) { }

  ngOnInit() {

    document.getElementById('weathermap').innerHTML = '<div id="map" style="width: 100%; height: 100%;"></div>';

    const map = new L.map('map', {
        zoom: 5,
        center: [18.8, 112.75],
        zoomControl: true
    });

    tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      detectRetina: true,
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    const params = this.activatedRoute.queryParams.subscribe(p => {
       this.ors.getOrderRouteByUID(p['uid'])
          .subscribe(posList => {
            this.lastMarker = null;
            this.create(map, posList);
          });
    });
    // this.test();
  }

  create(map: any, posList: MapPosition[]) {
    let lastPoint = this.createByChainIndex(map, posList, null, 0);
    lastPoint = this.createByChainIndex(map, posList, lastPoint, 1);
    this.createByChainIndex(map, posList, lastPoint, 2);

    if (this.lastMarker !== null) {
      this.lastMarker.setIcon(icon({
        iconSize: [ 12, 20 ],
        iconAnchor: [ 6, 20 ],
        iconUrl: 'leaflet/marker-icon.png'
      }));
      this.lastMarker.bindPopup(this.lastMarker.getTooltip().getContent().toString());
      setTimeout(() => {
          map.setView(this.lastMarker.getLatLng(), map.getZoom());
          this.lastMarker.openPopup();
        }, 300);
    }
  }

  createByChainIndex(map: any, posList: MapPosition[], prevPos: L.LatLng, chainIndex: number): L.LatLng {
    let result: L.LatLng = null;
    if (posList !== null && posList.length > 0) {
      const route = [];
      for (let i = 0; i < posList.length; i++) {
        const pos = posList[i];
        if (pos.chainIndex !== chainIndex) {
          continue;
        }
        if (prevPos !== null && result === null) {
          L.polyline([prevPos, latLng(pos.lat, pos.lon)],
            {
              dashArray: '10 7'
            }).addTo(map);
        }

        result = latLng(pos.lat, pos.lon);
        route.push( result );
        const m = marker(latLng(pos.lat, pos.lon), {
          icon: icon({
            iconSize: [ 16, 16 ],
            iconAnchor: [ 7, 7 ],
            iconUrl: 'assets/images/location.svg'
          })
        });

        if (DateTimeFactory.formatNow('') > pos.timestamp) {
          this.lastMarker = m;
        }

        m.bindTooltip(pos.title);
        m.addTo(map);
      }

      if (posList.length > 1) {
        L.polyline(route).addTo(map);
      }
    }
    return result;
  }
}
