import { isFakeMousedownFromScreenReader } from '@angular/cdk/a11y';

export class MapPosition {
  constructor(
    public lat: number,
    public lon: number,
    public title: string,
    public chainIndex: number,
    public timestamp: string,
    public is_fake: boolean
  ) { }
}

export class MapPositionRaw {
  constructor(
    public MMSI: string,
    public LAT: string,
    public LON: string,
    public SPEED: string,
    public HEADING: string,
    public COURSE: string,
    public STATUS: string,
    public TIMESTAMP: string,
    public DSRC: string,
    public is_fake?: boolean
  ) { }

  public isFake(): boolean {
    if (this.is_fake==null || this.is_fake===undefined) {
      return false
    }
    return this.is_fake
  }
}
