import { Document } from '../shared/document/document';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'osca-document',
  templateUrl: './document.component.html',
  styles: []
})

export class DocumentComponent {
  @Input() documentList: Document[];
  @Input() uid: string;

  constructor( ) { }


}
