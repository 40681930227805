import { Address } from './../../model/address';
import { DateTimeFactory } from './../../model/date-factory';
import { AddressCoord } from './address-coord';
import { MapPosition } from './../map/map-position';

export class AddressFactory {

  static fromAddressCoord(addressCoord: AddressCoord): MapPosition {
    if (addressCoord === null) {
      return null;
    }
    return new MapPosition(
      Number(addressCoord.lat),
      Number(addressCoord.lon),
      addressCoord.display_name,
      0,
      DateTimeFactory.now(),
      false
    );
  }

  static fromObject(address: Address): Address {
    if (address === undefined || address === null) {
      return null;
    }
    return new Address(
      address.reference_no,
      address.street,
      address.zip,
      address.city,
      address.country,
      address.contact_name,
      address.email,
      address.phone,
      address.fax,
      address.lon,
      address.lat,
      address.name,
      address.type
    );
  }

  static fromList(addressList: Address[]): Address[] {
    if (addressList === undefined || addressList === null) {
      return null;
    }
    const result = new Array<Address>();
    for (const a of addressList) {
      result.push(AddressFactory.fromObject(a));
    }
    return result;
  }
}
