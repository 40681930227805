import { QuotationService } from './../shared/quotation/quotation-service';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'osca-quotation-service',
  templateUrl: './quotation-service.component.html',
  styles: []
})
export class QuotationServiceComponent {
  @Input() serviceList: QuotationService[];

  constructor() { }

}
