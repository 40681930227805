export class Condition {
    constructor(
        public value: string,
        public type: string,
        public format: string
    ){}

    static create(): Condition {
        return new Condition("", "", "");
    }

    public addParam(name: string, value: any) {
        switch (name) {
            case "type": {
                this.type = value;
                break;
            }
            case "filterType": {
                this.format = value;
                break;
            }
            case "filter": {
                this.value = value;
                break;
            }
        }
    }
}

export class ColumnFilter {
    constructor(
        public name: string,
        public condition: Condition[],
        public op: string
    ) { }

    static create(): ColumnFilter {
        return new ColumnFilter("", null, "");
    }

    public addCondition(c: Condition) {
        if (c===undefined || c==null) {
            return;
        }
        if (this.condition===undefined || this.condition==null) {
            this.condition = [];
        }
        this.condition.push(c)
    }

    public getCondition(): Condition {
        if (this.condition===undefined || this.condition==null || this.condition.length==0) {
            this.condition = [Condition.create()];
        }
        return this.condition[0];
    }
}

export class OverviewFilter {
    constructor(
        public filter: ColumnFilter[]
    ) { }
}
