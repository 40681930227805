import { Router } from '@angular/router';
import { Container } from './../shared/container/container';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'osca-container',
  templateUrl: './container.component.html',
  styles: []
})
export class ContainerComponent implements OnInit {
  @Input() containerList: Container[];
  @Input() shipmentUID: string;

  constructor(private router: Router) { }

  ngOnInit() {
  }

}
