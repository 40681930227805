<div class="ui segment" *ngIf="quotation" style="border:0px">
  <div class="ui stackable two column grid" style="position:relative">
    <div class="column" style="width:25%;overflow-x:scroll">
      <div class="ui label">Quotation</div>
      <table class="ui single line stackable very compact small table">
        <tbody>
          <tr>
            <td><b>ID</b></td>
            <td>{{ quotation.id }}</td>
          </tr>
          <tr *ngIf="quotation.rfqQuotationId">
            <td>RFQ ID</td>
            <td>{{ quotation.rfqQuotationId }}</td>
          </tr>
          <tr *ngIf="quotation.rfqName">
            <td>RFQ Name</td>
            <td>{{ quotation.rfqName }}</td>
          </tr>
          <tr *ngIf="quotation.quotationType">
            <td>Type</td>
            <td>{{ quotation.quotationType }}</td>
          </tr>
          <tr *ngIf="quotation.mot">
            <td>MOT</td>
            <td>{{ quotation.mot }}</td>
          </tr>
          <tr *ngIf="quotation.customer">
            <td>Customer</td>
            <td>{{ quotation.customer }}</td>
          </tr>
          <tr *ngIf="quotation.forwarder">
            <td>Forwarder</td>
            <td>{{ quotation.forwarder }}</td>
          </tr>
          <tr *ngIf="quotation.validFrom">
            <td>Valid From</td>
            <td>{{ quotation.validFrom }}</td>
          </tr>
          <tr *ngIf="quotation.validUntil">
            <td>Valid Until</td>
            <td>{{ quotation.validUntil }}</td>
          </tr>
          <tr *ngIf="quotation.origin">
            <td>Origin</td>
            <td>{{ quotation.origin }}</td>
          </tr>
          <tr *ngIf="quotation.destination">
            <td>Destination</td>
            <td>{{ quotation.destination }}</td>
          </tr>
          <tr *ngIf="quotation.via">
            <td>Via</td>
            <td>{{ quotation.via }}</td>
          </tr>
          <tr *ngIf="quotation.conversionCbmToKg">
            <td>Cbm to kg</td>
            <td>{{ quotation.conversionCbmToKg }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="ui horizontal divider" style="width:3%"></div>
    <div class="column" style="width:70%">
      <div class="ui stackable grid">
        <div class="ui row" *ngIf="quotation.quotationServices">
          <osca-quotation-service [serviceList]="quotation.quotationServices" style="overflow-x: scroll"></osca-quotation-service>
        </div>
        <div class="ui row" *ngIf="quotation.quotationPositions">
          <osca-quotation-position [posList]="quotation.quotationPositions" style="overflow-x: scroll"></osca-quotation-position>
        </div>
      </div>
    </div>
  </div>
</div>
