
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map, retry} from 'rxjs/operators';
import { Router } from '@angular/router';
import { ServiceResponse } from './../service-response';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';




import { Booking } from './booking';
import { BookingFactory } from './booking-factory';
import {environment} from '../../../environments/environment';

@Injectable()
export class BookingService {
  private endpoint = environment.host + environment.basePath + '/v1/booking';

  bookingList: Booking[];

  constructor(private http: HttpClient) { }

  private errorHandler(error: Error | any): Observable<any> {
    return observableThrowError(error);
  }

  getSingle(uid): Observable<Booking> {
    return this.http
      .get<ServiceResponse>(`${this.endpoint}?uid=${uid}`).pipe(
      retry(3),
      map(rsp => rsp.payload[0] ),
      map(rawBooking => BookingFactory.fromObject(rawBooking as Booking)),
      catchError(this.errorHandler),);
  }

  resetVersion(uid): Observable<string> {
    return this.http
      .get<ServiceResponse>(`${this.endpoint}/reset-version?uid=${uid}&role=CUSTOMER`).pipe(
      retry(3),
      map(rsp => {
        if (rsp!==null && rsp.error!==null) {
          console.log(rsp.error)
        }
      } ),
      catchError(this.errorHandler),);
  }

  getAll(uid): Observable<Array<Booking>> {
    return this.http
      .get<ServiceResponse>(`${this.endpoint}?uid=${uid}`).pipe(
      retry(3),
      map(response => response.payload),
      catchError(this.errorHandler),);
  }
}
