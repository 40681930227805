
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map, retry} from 'rxjs/operators';
import { QuotationFactory } from './quotation-factory';
import { Quotation } from './quotation';
import { ServiceResponse } from './../service-response';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class QuotationService {
  private endpoint = environment.host + environment.basePath + '/v1/tcc/quotations';

  quotation: Quotation;

  constructor(private http: HttpClient) { }

  private errorHandler(error: Error | any): Observable<any> {
    return observableThrowError(error);
  }

  getSingle(qid): Observable<Quotation> {
    return this.http
      .get<ServiceResponse>(`${this.endpoint}/${qid}`).pipe(
      retry(3),
      map(rawQuotation => QuotationFactory.fromObject(rawQuotation as Quotation)),
      catchError(this.errorHandler),);
  }

}
