<div class="container">
  <div *ngIf="subject==='account'"><button mat-button (click)="createNew()" class="ui small button" style="margin-bottom:5px" title="New Account"><mat-icon matSuffix>create</mat-icon> </button></div>
  <ag-grid-angular
    class="ag-theme-balham"
    [components]="components"
    [rowBuffer]="rowBuffer"
    [rowSelection]="rowSelection"
    [suppressRowDeselection]="false"
    [rowModelType]="rowModelType"
    [paginationPageSize]="paginationPageSize"
    [cacheOverflowSize]="cacheOverflowSize"
    [maxConcurrentDatasourceRequests]="maxConcurrentDatasourceRequests"
    [infiniteInitialRowCount]="infiniteInitialRowCount"
    [cacheBlockSize]="cacheBlockSize"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    (filterChanged)="onFilterModified($event)"
    (rowDoubleClicked)="onRowDoubleClicked($event)"
    (gridReady)="onGridReady($event)">
  </ag-grid-angular>
  <div style="margin-top: 5px" *ngIf="totalRows!==undefined">Total: {{ totalRows }}</div>
</div>
