<div class="ui label" *ngIf="posList.length>1">Positions ({{posList.length}})</div>
<div class="ui label" *ngIf="posList.length<=1">Position</div>
<table class="ui single line stackable very compact small table">
    <thead>
      <th colspan="2"><b>Key</b></th>
      <th><b>Name</b></th>
      <th><b>Container Type</b></th>
      <th><b>Calculation Unit</b></th>
      <th><b>Range</b></th>
      <th><b>Cost Per Unit</b></th>
      <th><b>Native Currency</b></th>
      <th><b>Cost Group</b></th>
      <th><b>Percent Calc Incl.</b></th>
      <th><b>Vat</b></th>
      <th><b>MW-flag</b></th>
      <th><b>Post Cost Incl.</b></th>
    </thead>
  <tbody>
    <ng-container *ngFor="let pos of posList">
    <tr>
      <td colspan="2">{{ pos.key }}</td>
      <td>{{ pos.name }}</td>
      <td>{{ pos.containerType }}</td>
      <td>{{ pos.calculationUnit }}</td>
      <td>{{ pos.range }}</td>
      <td>{{ pos.costPerUnit }}</td>
      <td>{{ pos.nativeCurrency }}</td>
      <td>{{ pos.costGroup }}</td>
      <td>{{ pos.percentCalcIncluded }}</td>
      <td>{{ pos.vat }}</td>
      <td>{{ pos.mwFlag }}</td>
      <td>{{ pos.postCostIncluded }}</td>
    </tr>
    <tr *ngFor="let p of pos.quotationPositions">
      <td width="50px" *ngIf="p.quotationPositions.length==0">&nbsp;</td>
      <td *ngIf="p.quotationPositions.length>0">+{{p.quotationPositions.length}} subpositions</td>
      <td>{{ p.key }}</td>
      <td>{{ p.name }}</td>
      <td>{{ p.containerType }}</td>
      <td>{{ p.calculationUnit }}</td>
      <td width="50px" *ngIf="p.range==null">&nbsp;</td>
      <td *ngIf="p.range!=null">From {{ p.range.from }} to {{ p.range.to }}</td>
      <td>{{ p.costPerUnit }}</td>
      <td>{{ p.nativeCurrency }}</td>
      <td>{{ p.costGroup }}</td>
      <td>{{ p.percentCalcIncluded }}</td>
      <td>{{ p.vat }}</td>
      <td>{{ p.mwFlag }}</td>
      <td>{{ p.postCostIncluded }}</td>
    </tr>
    </ng-container>
  </tbody>
</table>
