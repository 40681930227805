import { PartnerService } from './../shared/partner/partner.service';
import { Partner } from './../model/partner';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'osca-partner',
  templateUrl: './partner.component.html',
  styles: []
})
export class PartnerComponent implements OnInit {
  @Input() partner: Partner;
  @Input() caption: string;

  constructor(private partnerService: PartnerService) { }

  ngOnInit() {
    if (this.partner === undefined || this.partner === null) {
      return;
    }
    this.partnerService.getAllInfo(this.partner.uid).subscribe(
      partner => this.partner = (partner !== null) ? partner : this.partner
    );
  }

}
