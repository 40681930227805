import { environment } from '../../../environments/environment';
import { ServiceResponse } from '../service-response';
import { throwError, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retry, catchError, map } from 'rxjs/operators';
import { Transaction } from './transaction';
import { TransactionFactory } from './transaction-factory';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  private endpointInfo = environment.host + environment.basePath + '/v1';

  transactionList: Transaction[];

  constructor(private http: HttpClient) { }

  private errorHandler(error: Error | any): Observable<any> {
    return throwError(() => error);
  }

  getAll(serviceName, uid): Observable<Transaction[]> {
    return this.http
      .get<ServiceResponse>(`${this.endpointInfo}/${serviceName}/transaction?uid=${uid}`)
      .pipe(
        retry(3),
        map(rsp => {
          if (rsp.payload === null) {
            return null;
          }
          if (rsp.error != null) {
            window.alert(rsp.error)
            return null;
          }
          return rsp.payload;
        } ),
        map(list => {
          if (list!==null)
            return TransactionFactory.fromList(list as Transaction[]);
          return null;
        }),
        catchError(this.errorHandler),
      );
  }

  reTrigger(serviceName, uid, command, hash) {
      let requestOptions: Object = { };
      return this.http.post<Observable<Blob>>(`${this.endpointInfo}/${serviceName}/transaction?uid=${uid}&command=${command}&hash=${hash}`, null, requestOptions)
        .pipe(
          retry(3),
          catchError(this.errorHandler)
        ).subscribe(res => {
          window.alert("Server answered: " + res.payload + ".\nError: " + res.error);
        });
  }
}
