<div class="ui label" *ngIf="itemList.length>1">Items ({{itemList.length}})</div>
<div class="ui label" *ngIf="itemList.length==1">Item(s)</div>
<table class="ui single line stackable very compact small table" *ngIf="itemList.length>0">
  <tbody>
    <tr>
      <td><b>Reference No.</b></td>
      <td><b>Position</b></td>
      <td><b>Number</b></td>
      <td><b>Description</b></td>
      <td><b>Qty</b></td>
    </tr>
    <tr *ngFor="let item of itemList">
      <td>{{ item.reference_no }}</td>
      <td>{{ item.position }}</td>
      <td>{{ item.number }}</td>
      <td>{{ item.description }}</td>
      <td>{{ item.qty }} {{ item.unit }}</td>
      <td>
        <div class="ui row" *ngIf="item.price">
          <osca-price [price]="item.price"></osca-price>
        </div>
      </td>
      <td>
        <div class="ui row" *ngIf="item.customs_info">
          <osca-customs-info [customsInfo]="item.customs_info"></osca-customs-info>
        </div>
      </td>
    </tr>
  </tbody>
</table>
