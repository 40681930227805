import { DataspacesService } from './dataspaces.service';
import { AuthData } from './../auth/auth-data';
import { UntypedFormControl } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'osca-dataspaces',
  templateUrl: './dataspaces.component.html',
  styles: []
})
export class DataspacesComponent implements OnInit {

  clientControl = new UntypedFormControl();
  dataspaces: string[];

  constructor(private authData: AuthData,
    private dataspacesService: DataspacesService) {  }

  ngOnInit() {
    const spaces = this.authData.getSpaces();
    if (this.dataspaces === undefined) {
      this.dataspaces = [];
      for (let i = 0; i < spaces.length; i++) {
        this.dataspaces.push(spaces[i].name);
      }
    }

    if (this.authData.getPartnerName() === undefined) {
      if (spaces.length > 0) {
        this.clientControl.setValue(this.dataspaces[0]);
        this.authData.selectSpace(this.dataspaces[0]);
      }
    } else {
      this.clientControl.setValue(this.authData.getPartnerName());
    }
  }

  selectDataspace(event: any) {
    this.dataspacesService.onDataspaceChanged(event.value);
    this.authData.selectSpace(event.value);
  }

}
