<div class="ui label">Route</div>
<table class="ui single line stackable very compact small table" *ngIf="timetable">
  <tbody>
    <tr>
      <td>
        <b>MOT</b>
      </td>
      <td>
        {{ timetable.mot }}
      </td>
    </tr>
    <tr *ngIf="timetable.service">
      <td>
        <b>Service</b>
      </td>
      <td>
        {{ timetable.service }}
      </td>
    </tr>
    <tr *ngIf="timetable.handover">
      <td>
        <b>Handover</b>
      </td>
      <td>
        {{ timetable.handover }}
      </td>
    </tr>
    <tr *ngIf="timetable.incoterm">
      <td>
        <b>Incoterm</b>
      </td>
      <td>
        {{ timetable.incoterm }}
      </td>
    </tr>
    <tr *ngIf="timetable.pol">
      <td>
        <b>POL</b>
      </td>
      <td>
        <a *ngIf="timetable.pol"
                  target="_blank" [routerLink]="['/map_address']"
                  [queryParams]="{ address: timetable.pol }">
                {{ timetable.pol }}
        </a>
      </td>
    </tr>
    <tr *ngIf="timetable.etd">
      <td>
        <b>ETD</b>
      </td>
      <td>
        {{ timetable.etd }}
      </td>
    </tr>
    <tr *ngIf="timetable.poa">
      <td>
        <b>POA</b>
      </td>
      <td>
        <a *ngIf="timetable.poa"
                  target="_blank" [routerLink]="['/map_address']"
                  [queryParams]="{ address: timetable.poa }">
                {{ timetable.poa }}
        </a>
      </td>
    </tr>
    <tr *ngIf="timetable.eta">
      <td>
        <b>ETA</b>
      </td>
      <td>
        {{ timetable.eta }}
      </td>
    </tr>
    <tr *ngIf="timetable.delivery_date">
      <td>
        <b>Delivery Date</b>
      </td>
      <td>
        {{ timetable.delivery_date }}
      </td>
    </tr>
    <tr *ngIf="timetable.status">
      <td>
        <b>Status</b>
      </td>
      <td>
        {{ timetable.status }}
      </td>
    </tr>
  </tbody>
</table>
