<div class="ui segment" *ngIf="order" style="border:0px">
  <div class="ui stackable two column grid" style="position:relative">
    <div class="column" style="width:25%;overflow-x:scroll">
      <div class="ui label">Order {{ order.customer_refno }}</div>
      <table class="ui single line stackable very compact small table">
      <tbody>
        <tr>
          <td><b>Reference No.</b></td>
          <td>{{ order.reference_no }}</td>
        </tr>
        <tr>
          <td><b>Order No.</b></td>
          <td>
              <a target="_blank" [routerLink]="['/map_order']" [queryParams]="{uid: order.uid }">
                {{ order.order_no }}
              </a>
          </td>
        </tr>
        <tr>
          <td><b>Order Date</b></td>
          <td>{{ order.order_date }}</td>
        </tr>
        <tr>
          <td><b>Created at</b></td>
          <td>{{ order.created_at }}</td>
        </tr>
        <tr>
          <td><b>Updated at</b></td>
          <td>{{ order.updated_at }}</td>
        </tr>
        <tr *ngIf="order.order_type">
          <td><b>Order Type</b></td>
          <td>{{ order.order_type }}</td>
        </tr>
        <tr *ngIf="order.department">
          <td><b>Department</b></td>
          <td>{{ order.department }}</td>
        </tr>
        <tr *ngIf="order.origin_country">
          <td><b>Country of Origin</b></td>
          <td>{{ order.origin_country }}</td>
        </tr>
        <tr *ngIf="order.payment">
          <td><b>Payment</b></td>
          <td>{{ order.payment }}</td>
        </tr>
        <tr *ngIf="order.dispatch_type">
          <td><b>Dispatch Type</b></td>
          <td>{{ order.dispatch_type }}</td>
        </tr>
        <tr>
          <td><b>Qty ord.</b></td>
          <td>{{ order.qty }} {{ order.unit }}</td>
        </tr>
        <tr *ngIf="order.cd_earliest">
          <td><b>CD 1</b></td>
          <td>{{ order.cd_earliest }}</td>
        </tr>
        <tr *ngIf="order.cd_latest">
          <td><b>CD 2</b></td>
          <td>{{ order.cd_latest }}</td>
        </tr>
        <tr *ngIf="order.status">
          <td><b>Status</b></td>
          <td>{{ order.status }}</td>
        </tr>
      </tbody>
      </table>
      <osca-timetable [timetable]="order.timetable"></osca-timetable>
    </div>
    <div class="ui horizontal divider" style="width:3%"></div>
    <div class="column" style="width:70%">
      <div class="ui stackable grid">
        <div class="ui row">
          <osca-partner-tabs
                [customer]="order.customer"
                [supplier]="order.supplier"
                [shipper]="order.shipper"
                [factory]="order.factory"
                [forwarder]="order.forwarder"
                [warehouse]="order.warehouse"
                [final_customer]="order.final_customer"
          >
          </osca-partner-tabs>
        </div>

        <div class="ui row" *ngIf="order.item">
          <osca-item [itemList]="order.item"></osca-item>
        </div>

        <div class="ui row" *ngIf="order.content">
          <osca-content [dataSource]="order.content"></osca-content>
        </div>

      </div>
    </div>
  </div>
</div>
