import { Component, Input } from '@angular/core';
import { PrintPDFService } from './print-pdf.service';

@Component({
  selector: 'osca-print-pdf',
  templateUrl: './print-pdf.component.html',
  styles: []
})

export class PrintPDFComponent {
  @Input() uid: string;
  @Input() subject: string;

  constructor( 
    private ps: PrintPDFService) { }

  print() {
    this.ps.getSingle(this.subject, this.uid).subscribe((resultBlob: Blob) => {
      console.log(resultBlob)
      var link = document.createElement('a');
      link.href = URL.createObjectURL(resultBlob);
      link.download = this.subject + '_' + this.uid;
      link.click();
    }
    )
  }

}
