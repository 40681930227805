import { DateTimeFactory } from '../../model/date-factory';
import { Thread } from './message';

export class MessageFactory {
  static fromList(threadList: Thread[]): Thread[] {
    if (threadList === undefined) {
      return null;
    }
    const result = new Array<Thread>();
    for (let rawThread of threadList) {
      for (let rawEmail of rawThread.email) {
        rawEmail.created_at = DateTimeFactory.dateTimeValueParser(rawEmail.created_at)
        rawEmail.text = rawEmail.text.replace(/\n/g, '<br/>')
      }
      rawThread.email.sort(function(a, b) { return b.version - a.version; })
      result.push(rawThread);
    }
    return result;
  }
}
