  <div class="ui segment" *ngIf="account" style="border:0px">
    <div class="ui stackable two column grid">
      <div class="six wide column" style="overflow-x:scroll;">
<form class="ui large form" [formGroup]="accountForm" (ngSubmit)="submitAccount()">
        <h2>Account</h2>
        <table  cellspacing="0">
          <tr><td><mat-form-field>
            <input matInput placeholder="Consumer" formControlName="consumer">
          </mat-form-field></td>
        <td><button mat-button class="ui small button"> <mat-icon matSuffix>save</mat-icon> </button></td></tr>
          <tr><td><mat-form-field>
            <input matInput placeholder="Origin" formControlName="origin">
          </mat-form-field></td></tr>
          <tr><td><mat-form-field>
            <mat-select matInput placeholder="Role" formControlName="role">
              <mat-option value="CUSTOMER">CUSTOMER</mat-option>
              <mat-option value="FORWARDER">FORWARDER</mat-option>
              <mat-option value="SUPPLIER">SUPPLIER</mat-option>
              <mat-option value="ADMIN">ADMIN</mat-option>
            </mat-select>
          </mat-form-field></td></tr>
          <tr><td><mat-form-field>
            <input matInput placeholder="Name" formControlName="name">
          </mat-form-field>
          <tr *ngIf="account.origin.length>0"><td><mat-form-field>
              <input matInput placeholder="API key" [type]="hideAPIkey ? 'password' : 'text'" formControlName="apikey">
              <mat-icon style="vertical-align: middle; padding: 8pt 0 0 5pt" matSuffix (click)="hideAPIkey = !hideAPIkey">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </mat-form-field></td></tr>
          <tr *ngIf="account.origin.length>0">
            <td>
                <button mat-button (click)="generateAPIKey()" class="ui button">Generate an API key!</button>
            </td>
          </tr>
        </table>
</form>
    </div>
    <div class="ui horizontal divider" style="width:3%"></div>
    <div class="nine wide column">
      <div class="ui stackable grid">
        <div class="ui row">
          <osca-user *ngIf="account.origin.length>0" [consumer]="account.consumer" [userList]="account.user"></osca-user>
        </div>
        <div class="ui row">
            <osca-partner-consumer *ngIf="account.origin.length>0" [origin]="account.origin" [consumer]="account.consumer" ></osca-partner-consumer>
          </div>
        </div>
    </div>
  </div>
</div>

<div style="color:red">{{ gatewayError }}</div>
<div *ngFor='let msg of messages' style="color:gray">{{ msg }}</div>
