import { Quotation } from './quotation';
import { PartnerFactory } from './../partner/partner-factory';
import { TimeTable } from './../../model/timetable';
import { DateTimeFactory } from './../../model/date-factory';
import { TimeTableFactory } from '../../timetable/timetable-factory';
import { RemarkFactory } from '../remark/remark-factory';
import { ContainerFactory } from '../container/container-factory';

export class QuotationFactory {
  static fromObject(rawQuotation: Quotation): Quotation {
    console.log(rawQuotation);
    if (rawQuotation.validFrom != null) {
      rawQuotation.validFrom = DateTimeFactory.dateValueParser(rawQuotation.validFrom);
    }
    if (rawQuotation.validUntil != null) {
      rawQuotation.validUntil = DateTimeFactory.dateValueParser(rawQuotation.validUntil);
    }
    return rawQuotation;
  }
}
