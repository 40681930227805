<div class="ui segment" *ngIf="shipment" style="border:0px">
  <div style="padding: 3px"><osca-print-pdf [uid]="shipment.uid" [subject]="'shipment'"></osca-print-pdf></div>
  <div class="ui stackable two column grid" style="position:relative">
    <div class="column" style="width:25%;overflow-x:scroll">
      <div class="ui label">Shipment</div>
      <table class="ui single line stackable very compact small table">
        <tbody>
          <tr>
            <td><b>UID</b></td>
            <td>{{ shipment.uid }}</td>
          </tr>
          <tr>
            <td>Reference No.</td>
            <td *ngIf="shipment.timetable.mot==='TRUCK'">
              <a target="_blank" [routerLink]="['/map_truck']" [queryParams]="{departure: shipment.timetable.pol, arrival: shipment.timetable.poa, etd: shipment.timetable.etd }">
                {{ shipment.reference_no }}
              </a>
            </td>
            <td *ngIf="shipment.timetable.mot!=='TRUCK'">{{ shipment.reference_no }}</td>
          </tr>
          <tr>
            <td>Created at</td>
            <td>{{ shipment.created_at }}</td>
          </tr>
          <tr>
            <td>Updated at</td>
            <td>{{ shipment.updated_at }}</td>
          </tr>
          <tr *ngIf="shipment.status">
            <td>Status (Shipment)</td>
            <td>{{ shipment.status }}</td>
          </tr>
          <tr *ngIf="shipment.status_forwarder">
            <td>Status (Forwarder)</td>
            <td>{{ shipment.status_forwarder }}</td>
          </tr>
          <tr *ngIf="shipment.carrier">
            <td>Carrier</td>
            <td>{{ shipment.carrier }}</td>
          </tr>
          <tr *ngIf="shipment.vessel_airline">
            <td>Vessel/Airline</td>
            <td *ngIf="shipment.timetable.mot==='SEA'">
              <a target="_blank" [routerLink]="['/map_vessel']" [queryParams]="{name: shipment.vessel_airline, from: shipment.timetable.etd, to: shipment.timetable.eta }">
                {{ shipment.vessel_airline }}
              </a>
            </td>
<!--            <td *ngIf="shipment.timetable.mot==='SEA'">
              <a (click)="vesselOnMap()">
                {{ shipment.vessel_airline }}
              </a>
            </td>-->
            <td *ngIf="shipment.timetable.mot!=='SEA'">{{ shipment.vessel_airline }}</td>
          </tr>
        </tbody>
      </table>
      <osca-timetable [timetable]="shipment.timetable"></osca-timetable>
    </div>
    <div class="ui horizontal divider" style="width:3%"></div>
    <div class="column" style="width:70%">
      <div class="ui stackable grid">
        <div class="ui row" *ngIf="shipment.remark">
          <osca-remark [remarkList]="shipment.remark" style="overflow-x: scroll"></osca-remark>
        </div>
        <div class="ui row">
          <osca-partner-tabs
            [customer]="shipment.customer"
            [supplier]="shipment.supplier"
            [forwarder]="shipment.forwarder"
          >
          </osca-partner-tabs>
        </div>

        <div class="ui row" *ngIf="shipment.container">
          <osca-container [containerList]="shipment.container" [shipmentUID]="shipment.uid" style="overflow-x: scroll"></osca-container>
        </div>

        <div class="ui row">
          <osca-event-list [subject]="'shipment'" [uid]="shipment.uid"></osca-event-list>
        </div>

        <div class="ui row">
          <osca-document [uid]="shipment.uid" [documentList]="shipment.document"></osca-document>
        </div>

        <div class="ui row">
          <osca-transaction [subject]="'shipment'" [uid]="shipment.uid" style="overflow-x: scroll"></osca-transaction>
        </div>

<!--        <div class="ui row">
          <osca-message [subject]="'shipment'" [uid]="shipment.uid"></osca-message>
        </div>
-->
      </div>
    </div>
  </div>
</div>
