<div class="ui segment" *ngIf="booking" style="border:0px">
  <div style="padding: 3px">
    <table>
      <tr>
        <td>
          <osca-print-pdf [uid]="booking.uid" [subject]="'booking'"></osca-print-pdf>
        </td>
        <td>
          <button style="padding: 5px;" mat-button (click)="resetVersion()" class="ui small button" title="Reset Version"><mat-icon matSuffix>undo</mat-icon></button>
        </td>
      <td>{{ serverMessage }}</td></tr>
    </table>
  </div>
  <div class="ui stackable two column grid" style="position:relative">
    <div class="column" style="width:25%;overflow-x:scroll">
      <div class="ui label">Booking</div>
      <table class="ui single line stackable very compact small table">
      <tbody>
        <tr>
          <td><b>UID</b></td>
          <td>{{ booking.uid }}</td>
        </tr>
        <tr>
          <td><b>Reference No.</b></td>
          <td>{{ booking.reference_no }}</td>
        </tr>
        <tr *ngIf="booking.booking_no">
          <td><b>Booking No.</b></td>
          <td>{{ booking.booking_no }}</td>
        </tr>
        <tr>
          <td><b>Created at</b></td>
          <td>{{ booking.created_at }}</td>
        </tr>
        <tr>
          <td><b>Updated at</b></td>
          <td>{{ booking.updated_at }}</td>
        </tr>
        <tr *ngIf="booking.status">
          <td><b>Status (Customer)</b></td>
          <td>{{ booking.status }}</td>
        </tr>
        <tr *ngIf="booking.status_forwarder">
          <td><b>Status (Forwarder)</b></td>
          <td>{{ booking.status_forwarder }}</td>
        </tr>
        <tr *ngIf="booking.volume>0">
          <td><b>Volume</b></td>
          <td>{{ booking.volume }} m³</td>
        </tr>
        <tr *ngIf="booking.weight>0">
          <td><b>Weight</b></td>
          <td>{{ booking.weight }} kg</td>
        </tr>
        <tr *ngIf="booking.qty_pcs>0">
          <td><b>Qty pcs.</b></td>
          <td>{{ booking.qty_pcs }}</td>
        </tr>
        <tr *ngIf="booking.qty_ctn>0">
          <td><b>Qty ctn.</b></td>
          <td>{{ booking.qty_ctn }}</td>
        </tr>
        <tr *ngIf="booking.carrier">
          <td><b>Carrier</b></td>
          <td>{{ booking.carrier }}</td>
        </tr>
         <tr *ngIf="booking.vessel_airline">
          <td><b>Vessel/Airline</b></td>
          <td>{{ booking.vessel_airline }}</td>
        </tr>
      </tbody>
      </table>
      <osca-timetable [timetable]="booking.timetable"></osca-timetable>
    </div>
    <div class="ui horizontal divider" style="width:3%"></div>
    <div class="column" style="width:70%">
      <div class="ui stackable grid">
        <div class="ui row" *ngIf="booking.remark">
          <osca-remark [remarkList]="booking.remark" style="overflow-x: scroll"></osca-remark>
        </div>
        <div class="ui row" *ngIf="booking.address">
          <osca-address [addressList]="booking.address" style="overflow-x: scroll"></osca-address>
        </div>

        <div class="ui row" *ngIf="booking.container">
          <osca-container [containerList]="booking.container" [shipmentUID]="" style="overflow-x: scroll"></osca-container>
        </div>

        <div class="ui row" *ngIf="booking.delivery">
          <osca-delivery [deliveryList]="booking.delivery" style="overflow-x: scroll"></osca-delivery>
        </div>

        <div class="ui row">
          <osca-partner-tabs
            [customer]="booking.customer"
            [supplier]="booking.supplier"
            [shipper]="booking.shipper"
            [factory]="booking.factory"
            [forwarder]="booking.forwarder"
            [warehouse]="booking.warehouse"
          >
          </osca-partner-tabs>
        </div>

      <div class="ui row">
        <osca-event-list [subject]="'booking'" [uid]="booking.uid"></osca-event-list>
      </div>

<!--      <div class="ui row">
        <osca-message [subject]="'booking'" [uid]="booking.uid"></osca-message>
      </div>
-->
      <div class="ui row">
        <osca-transaction [subject]="'booking'" [uid]="booking.uid" style="overflow-x: scroll"></osca-transaction>
      </div>

      </div>
    </div>
  </div>
</div>
