import { TimetableComponent } from './../timetable/timetable.component';
import { BookingFactory } from './../shared/booking/booking-factory';
import { BookingService } from '../shared/booking/booking.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Booking } from '../shared/booking/booking';

@Component({
  selector: 'osca-booking',
  templateUrl: './booking.component.html',
  styles: []
})

export class BookingComponent implements OnInit {
  booking: Booking;

  serverMessage: string;

  constructor(
    private bs: BookingService,
    private route: ActivatedRoute
  ) { }


  ngOnInit() {
    const params = this.route.snapshot.params;
    this.bs.getSingle(params['uid']).subscribe(b => this.booking = b);
//    this.booking = new Booking('1234', 'ABC', 0, 'BN', 'aösdkf', 'sjf', 'STOP', 0, 0, 0, 0, '', null, '');
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async showMessage(msg) {
    this.serverMessage = msg;
    await this.sleep(3000)
    this.serverMessage = ""
  }

  resetVersion() {
    const params = this.route.snapshot.params;
    this.bs.resetVersion(params['uid']).subscribe(result => this.showMessage("Version has been set to 0"))
  }

}
