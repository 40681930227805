import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServiceResponse } from '../service-response';
import { environment } from './../../../environments/environment';
import { throwError, Observable } from 'rxjs';
import { Account, User, PartnerConsumer } from './account';
import { retry, map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class AccountService {
  account: Account;

  endpoint = environment.host + environment.basePath + '/v1/account';

  headers = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' })
  };

  constructor(
    private http: HttpClient
  ) { }

  private errorHandler(error: Error | any): Observable<any> {
    return throwError(() => error);
  }

  getAccount(consumer): Observable<Account> {
    return this.http
      .get<ServiceResponse>(`${this.endpoint}?consumer=${consumer}&with_users=true`)
      .pipe(
        retry(3),
        map(rsp => {
          if (rsp.payload === undefined || rsp.payload === null || rsp.payload.length < 1) {
            return null;
          }
          return rsp.payload as any;
        } ),
        map(payload => payload as Account),
        catchError(this.errorHandler),
      );
  }

  getPartnerConsumers(origin: string): Observable<PartnerConsumer[]> {
    return this.http
      .get<ServiceResponse>(`${this.endpoint}/consumer?origin=${origin}`)
      .pipe(
        retry(3),
        map(rsp => {
          if (rsp.payload === undefined || rsp.payload === null || rsp.payload.length < 1) {
            return null;
          }
          return rsp.payload as PartnerConsumer[];
        } ),
        catchError(this.errorHandler),
      );
  }

  createUser(user: User) {
    return this.http.post(`${this.endpoint}/user`, user, this.headers)
      .pipe(
        retry(3),
        catchError(this.errorHandler),
      ).subscribe(res => {
        window.location.reload();
      });
  }

  updateUser(id: string, user: User) {
    if (id=='') {
      console.error('could not update user, empty id received')
      return
    }
    return this.http.patch(`${this.endpoint}/user?username=${id}`, user, this.headers)
      .pipe(
        retry(3),
        catchError(this.errorHandler),
      ).subscribe(res => {
        window.location.reload();
      });
  }

  createPartnerConsumer(partnerConsumer) {
    return this.http.post(`${this.endpoint}/consumer`, partnerConsumer, this.headers)
      .pipe(
        retry(3),
        catchError(this.errorHandler),
      ).subscribe(res => {
        window.location.reload();
      });
  }

  updatePartnerConsumer(oldRefNo: string, oldConsumer: string, partnerConsumer: any) {
    if (oldRefNo=='') {
      console.error('could not update partner, empty ref-no received')
      return
    }

    return this.http.patch(`${this.endpoint}/consumer?reference_no=${oldRefNo}&consumer=${oldConsumer}`, partnerConsumer, this.headers)
        .pipe(
          retry(3),
          catchError(this.errorHandler),
        ).subscribe(res => {
          window.location.reload();
        });
  }

  generateAPIKey(consumer: string): Observable<string>{
    return this.http
      .get<ServiceResponse>(`${this.endpoint}/apikey?consumer=${consumer}`)
      .pipe(
        retry(3),
        map(rsp => {
          if (rsp.payload === undefined || rsp.payload === null) {
            return null;
          }
          return rsp.payload as any;
        } ),
        map(payload => payload as string),
        catchError(this.errorHandler),
      );
  }

  postAccount(account: Account): Observable<string> {
    return this.http.post(`${this.endpoint}`, account, this.headers)
        .pipe(
          retry(3),
          catchError(this.errorHandler),
          map(res => {
            return "Account saved."
          }),
        );
  }
}
