
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map, retry} from 'rxjs/operators';
import { AddressFactory } from './address-factory';
import { AddressCoord } from './address-coord';
import { ServiceResponse } from './../service-response';
import { MapPosition } from './../map/map-position';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  private endpoint = environment.host + environment.basePath + '/v1/address';

  constructor(private http: HttpClient) { }

  private errorHandler(error: Error | any): Observable<any> {
    return observableThrowError(error);
  }

  getSingle(address: string): Observable<MapPosition> {
    if (address === undefined) {
      return new Observable<MapPosition>();
    }
    if (address !== undefined && address.length === 5) {
      address = address.substr(0, 2) + ',' + address.substr(2, 3);
    }
    return this.http
      .get<ServiceResponse>(`${this.endpoint}/address?address=${address}`).pipe(
      retry(3),
      map(rsp => {
        return rsp.payload === undefined ? null : rsp.payload as any;
       } ),
      map(rawAddress => AddressFactory.fromAddressCoord(rawAddress as AddressCoord)),
      catchError(this.errorHandler),);
  }

}
