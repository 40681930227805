import { QuotationService } from './../shared/quotation/quotation.service';
import { Quotation } from './../shared/quotation/quotation';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'osca-quotation',
  templateUrl: './quotation.component.html',
  styles: []
})
export class QuotationComponent implements OnInit, OnDestroy {

 quotation: Quotation;
 qtSubscription: Subscription;

  constructor(
    private qs: QuotationService,
    private route: ActivatedRoute
  ) { }


  ngOnInit() {
    const params = this.route.snapshot.params;
    this.qtSubscription = this.qs.getSingle(params['uid']).subscribe(q => {
      this.quotation = q;
    });
  }

  ngOnDestroy() {
    this.qtSubscription.unsubscribe();
  }

}
