<div class="ui label">Price</div>
<table class="ui single line stackable very compact small table">
  <tbody>
    <tr *ngIf="price.purchase_price !== undefined && price.purchase_price !== null && price.purchase_price > 0">
      <td><b>Purchase Price</b></td>
      <td>{{ price.purchase_price }} {{ price.purchase_currency }}</td>
    </tr>
    <tr *ngIf="price.sales_price !== undefined && price.sales_price !== null && price.sales_price > 0">
      <td><b>Sales Price</b></td>
      <td>{{ price.sales_price }} {{ price.sales_currency }}</td>
    </tr>
  </tbody>
</table>
