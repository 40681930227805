import { ActivatedRoute } from '@angular/router';
import { OrderService } from './../shared/order/order.service';
import { Order } from './../shared/order/order';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'osca-order',
  templateUrl: './order.component.html',
  styles: []
})
export class OrderComponent implements OnInit {
  order: Order;

  constructor(
    private os: OrderService,
    private route: ActivatedRoute
  ) { }


  ngOnInit() {
    const params = this.route.snapshot.params;
    this.os.getSingle(params['uid']).subscribe(o => this.order = o);
  }

}
